import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import backgroundImageLight from "assets/img/pattern.jpg";
import backgroundImageChicken from "assets/img/background3.jpg";

const baseTheme = createTheme({
  backgroundImg: `url(${backgroundImageLight})`,
  backgroundImgChicken: `url(${backgroundImageChicken})`,
  background: "#FFFFFF",
  color: "black",
  chartTextColor: "black",
  borderColor: "black",
  titleColor: "#776F6F",
  axisBarColor: "black",
  axisTextBarColor: "black",
  markerColor: "black",
  tACLineColor: "#5c96ff",
  tACBackLineColor: "black",
  generatorLegendColor: "black",
  svgColor: "black",
  svgFilledTextColor: "#FFF",
  palette: {
    error: {
      main: red.A400
    },
    mode: "light"
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
          },
          "& input[type=number]": {
            MozAppearance: "textfield"
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "AvenirNext";
        font-style: italic;
        font-weight: 400;
        font-display:"swap";
      }
      @font-face {
        font-family: "AvenirNext";
        font-style: normal;
        font-weight: 500;
        font-display:"swap";
      }
      @font-face {
        font-family: "AvenirNext";
        font-style: normal;
        font-weight: 400;
        font-display:"swap";
      
      }
      @font-face {
        font-family: "AvenirNext";
        font-style: normal;
        font-weight: 600;
        font-display:"swap";
       
      }
      @font-face {
        font-family: "AvenirNext";
        font-style: normal;
        font-weight: 700;
        font-display:"swap";
 
      }
      `
    }
  },
  typography: {
    fontFamily: ["Roboto", "AvenirNext", "Helvetica", "Arial", "serif"].join(
      ","
    ),
    body2: {
      fontSize: "0.95rem",
      letterSpacing: "0.2px"
    },
    subtitle1: {
      fontSize: "1.17rem",
      letterSpacing: "0.15px",
      fontWeight: 400
    },
    body3: {
      fontFamily: ["Open Sans", "sans-serif"].join(","),
      fontSize: "1.1rem",
      color: "#0d0e10"
    }
  }
});

export default baseTheme;
