import { createSlice } from "@reduxjs/toolkit";
import {
  deleteSensor,
  getSensor,
  updateSelectedSensor,
  getAllSensors
} from "./thunk";

export const sensorSlice = createSlice({
  name: "sensors",
  initialState: {
    sensors: [],
    selectedSensor: {},
    selectedSensorStatus: "idle",
    status: "idle",
    deleteStatus: "idle",
    updateSensorStatus: "idle",
    error: null
  },
  extraReducers: builder => {
    builder.addCase(deleteSensor.pending, (state, action) => {
      state.deleteStatus = "loading";
    });
    builder.addCase(deleteSensor.fulfilled, (state, action) => {
      state.deleteStatus = "succeeded";
    });
    builder.addCase(deleteSensor.rejected, (state, action) => {
      state.deleteStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getSensor.pending, (state, action) => {
      state.selectedSensorStatus = "loading";
    });
    builder.addCase(getSensor.fulfilled, (state, action) => {
      state.selectedSensorStatus = "succeeded";
      state.selectedSensor = action.payload;
    });
    builder.addCase(getSensor.rejected, (state, action) => {
      state.selectedSensorStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(updateSelectedSensor.pending, (state, action) => {
      state.updateSensorStatus = "loading";
    });
    builder.addCase(updateSelectedSensor.fulfilled, (state, action) => {
      state.updateSensorStatus = "succeeded";
    });
    builder.addCase(updateSelectedSensor.rejected, (state, action) => {
      state.updateSensorStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getAllSensors.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getAllSensors.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updateSensorStatus = "idle";
      state.sensors = action.payload;
    });
    builder.addCase(getAllSensors.rejected, (state, action) => {
      state.status = "failed";
    });
  }
});

export default sensorSlice.reducer;
