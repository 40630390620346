/* eslint-disable no-unused-vars */
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
// core components
import Grid from "@mui/material/Grid";

import styles from "assets/jss/components/suspenseFallbackStyle";

import loaderGif from "assets/img/loading.gif";

const useStyles = makeStyles(styles);

export default function SuspenseFallback(props) {
  const classes = useStyles();

  return (
    <div style={{ height: "100vh" }}>
      <Grid
        container
        className={classes.backgroundImage}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <div className={classes.container}>
            {/* <LoadingIcon color="white"></LoadingIcon> */}
            <img src={loaderGif} alt="loader" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

SuspenseFallback.propTypes = {};
