import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTimelinesAlertsByGalpon = createAsyncThunk(
  "get-timelines-by-galpon",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.timeline.getTimelinesAlertsByGalpon(
        argument
      );
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
