import { VIEWS } from "../../components/pages/vars/constants";
/* eslint-disable no-unused-vars */
import {
  initializeSettingsSuccess,
  updateSettings,
  updateCurrentsSettings,
  updateTheme,
  selectTabOption
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  windUnit: "km/h",
  currentsUnit: "cm/s",
  theme: "light",
  selectedOptionTab: 1,
  view: VIEWS.DEFAULT
};

const settings = handleActions(
  new Map([
    [
      initializeSettingsSuccess,
      (state, action) => ({
        ...state,
        windUnit: action.payload.data
      })
    ],
    [
      updateSettings,
      (state, action) => ({
        ...state,
        windUnit: action.payload.data
      })
    ],
    [
      updateCurrentsSettings,
      (state, action) => ({
        ...state,
        currentsUnit: action.payload.data
      })
    ],
    [
      updateTheme,
      (state, action) => ({
        ...state,
        theme: action.payload.data
      })
    ],
    [
      selectTabOption,
      (state, action) => ({
        ...state,
        selectedOptionTab: action.payload.data
      })
    ]
  ]),
  initialState
);

export default settings;
