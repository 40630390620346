import { axiosInstance } from "./api";

export default {
  getGalponesByPlantel: plantelId => {
    return axiosInstance.get(
      "/galpon?$sort[id]=-1&$limit=100&empresaId=" +
        sessionStorage.getItem("empresa") +
        "&plantelId=" +
        plantelId
    );
  },
  getGalponesByFarmAndPlantel: (farmId, plantelId) => {
    return axiosInstance.get(
      "/galpon?$sort[id]=-1&$limit=100&empresaId=" +
        sessionStorage.getItem("empresa") +
        "&plantelId=" +
        plantelId +
        "&granjaId=" +
        farmId
    );
  },
  getGalponesByFarmPlantelAndToken: (farmId, plantelId, token) => {
    return axiosInstance.get(
      "/galpon?$sort[id]=-1&$limit=100&empresaId=" +
        sessionStorage.getItem("empresa") +
        "&plantelId=" +
        plantelId +
        "&granjaId=" +
        farmId +
        "&gwtoken=" +
        token
    );
  },
  getAllGalpones: () => {
    return axiosInstance.get(
      "/galpon?$sort[id]=-1&empresaId=" + sessionStorage.getItem("empresa")
    );
  },
  getGalponesTrend_RP_ByPlantel: plantelId => {
    return axiosInstance.get("/rp-trend?plantelId=" + plantelId);
  },
  getGalponesTrend_CP_ByPlantel: plantelId => {
    return axiosInstance.get("/cp-trend?plantelId=" + plantelId);
  },
  getGalponById: id => axiosInstance.get(`/galpon/${id}`),
  createChickenCoop: data => axiosInstance.post("/galpon", data),
  deleteChickenCoop: data => axiosInstance.delete(`/galpon/${data.id}`),
  updateChickenCoop: ({ id, data }) => axiosInstance.put(`/galpon/${id}`, data)
};
