/* eslint-disable no-unused-vars */
import { createActions } from "redux-actions";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "GENERAL", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

// Los nombres de las variables siempre se pasan de snake_case a camelCase
export const {
  initializeGeneralSuccess,
  updateSelectedFarmIdState,
  updateSelectedFarmNameState,
  updateSelectedFarmHouseIdState,
  updateSelectedFarmHouseNameState
} = createActions(
  {
    INITIALIZE_GENERAL_SUCCESS: data => ({ data }),
    UPDATE_SETTINGS: data => ({ data }),
    UPDATE_CURRENTS_SETTINGS: data => ({ data }),
    UPDATE_THEME: data => ({ data }),
    UPDATE_SELECTED_FARM_ID_STATE: selectedFarmId => ({ selectedFarmId }),
    UPDATE_SELECTED_FARM_NAME_STATE: selectedFarmName => ({ selectedFarmName }),
    UPDATE_SELECTED_FARM_HOUSE_ID_STATE: selectedFarmHouseId => ({
      selectedFarmHouseId
    }),
    UPDATE_SELECTED_FARM_HOUSE_NAME_STATE: selectedFarmHouseName => ({
      selectedFarmHouseName
    })
  },
  actionOptions
);
