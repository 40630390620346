import { createRef, useState, useEffect, Fragment } from "react";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import SidebarContainer from "../../common/sidebar/sidebar";
import Menu from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import routes from "routeList.js";
import styles from "assets/jss/layouts/layoutStyle.js";
import logo from "assets/img/logo.png";
import ConfigMenuContainer from "containers/common/configMenu/configMenu";
import { connect } from "react-redux";
import { updateLoginState } from "store/login/actions";

import { getLoggedIn } from "utils/localStorageHelper";

let ps;

const useStyles = makeStyles(styles);

const mapStateToProps = state => ({
  loggedIn: state.login.loggedin
});

const mapDispatchToProps = dispatch => ({
  updateLogedIn: data => dispatch(updateLoginState(data))
});

const Layout = ({ ...rest }) => {
  // export default function Layout({ ...rest }) {
  // styles
  const theme = useTheme();
  const classes = useStyles(theme);
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileConfigOpen, setMobileConfigOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleConfigToggle = () => {
    setMobileConfigOpen(!mobileConfigOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
      setMobileConfigOpen(false);
    }
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  useEffect(() => {
    rest.updateLogedIn(getLoggedIn());
  }, []);

  return (
    <div className={classes.wrapper}>
      <SidebarContainer
        routes={routes}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        setMobileOpen={setMobileOpen}
        {...rest}
      />
      <ConfigMenuContainer
        handleDrawerToggle={handleConfigToggle}
        open={mobileConfigOpen}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <div className={classes.content}>
          <div className={classes.container}>{rest.children}</div>
        </div>
        {rest.loggedIn ? (
          <Fragment>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className={classes.fixedMenu}
              size="large"
            >
              <Menu />
            </IconButton>

            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleConfigToggle}
              className={classes.fixedConfigMenu}
              size="large"
            >
              <SettingsIcon />
            </IconButton> */}
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
