import routeList from "./routeList";
import { lazy, Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import SuspenseFallback from "components/common/suspenseFallback/suspenseFallback";
import { useSelector } from "react-redux";
import { hasPermission } from "utils/helpers";
import authRoutes from "authRoutes";
const Login = lazy(() => import("containers/pages/login/Login"));

export const AppRoutes = () => {
  const loggedin = useSelector(state => state.login.loggedin);
  // console.log(
  //   "🚀 ~ file: routes.js ~ line 10 ~ AppRoutes ~ loggedin",
  //   loggedin
  // );

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Switch>
        {routeList
          .filter(x => x.path)
          .map((prop, key) => {
            if (prop.path === "/login" || hasPermission(prop.permissions))
              return (
                <Route
                  exact
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
          })}
        {!sessionStorage.getItem("token") &&
          authRoutes.map((prop, key) => {
            return (
              <Route
                exact
                path={prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        <Redirect exact from="/" to="login" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
