const styles = {
  backgroundImage: {
    // backgroundImage: `url(${backgroudImage})`,
    backgroundSize: "cover",
    // background: "#3566CC",
    background: "white",

    height: "100vh"
  },
  container: {
    minHeight: "180px",
    flexDirection: "row",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    width: "100%"
  }
};

export default styles;
