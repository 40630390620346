import { createAsyncThunk } from "@reduxjs/toolkit";
export const getPlanteles = createAsyncThunk(
  "get-planteles",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.plantel.getPlantelesByFarm(argument);
      //console.log(`THUNK getPlanteles ${JSON.stringify(res.data.data)}`);

      const planteles = res.data.data.map(item => {
        return {
          ...item,
          id_mtech_number: parseInt(item.id_mtech)
        };
      });

      return (
        planteles.sort((a, b) => {
          return a.id_mtech_number - b.id_mtech_number; // TODO: Revisar si el valor id_mtech_number o id_mtech se manda en platneles porque esta trayendo NaN desde el BE
        }) ?? []
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAllPlanteles = createAsyncThunk(
  "get-all-planteles",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.plantel.getAllPlanteles();
      //console.log(`THUNK getPlanteles ${JSON.stringify(res.data.data)}`);

      const planteles = res.data.data.map(item => {
        return {
          ...item,
          id_mtech_number: parseInt(item.id_mtech)
        };
      });

      return (
        planteles.sort((a, b) => {
          return a.id_mtech_number - b.id_mtech_number; // TODO: Revisar si el valor id_mtech_number o id_mtech se manda en platneles porque esta trayendo NaN desde el BE
        }) ?? []
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAllActiveAlarmsPlanteles = createAsyncThunk(
  "get-all-active-alarms-planteles",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.plantel.getActiveAlarmsGroupByPlanteles();

      return res?.data?.planteles ?? [];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getPlantel = createAsyncThunk(
  "get-plantel",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.plantel.getPlantelById(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const createPlantel = createAsyncThunk(
  "create-plantel",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.plantel.createFarmHouses(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const deletePlantel = createAsyncThunk(
  "delete-plantel",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.plantel.deletePlantel(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const updateSelectedPlantel = createAsyncThunk(
  "update-plantel",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.plantel.updatePlantel(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
