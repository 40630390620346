/* eslint-disable no-unused-vars */
import { updateLoginState, isLoggedIn } from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  loggedin: false
};

const login = handleActions(
  new Map([
    [
      updateLoginState,
      (state, action) => ({
        ...state,
        loggedin: action.payload.data
      })
    ]
  ]),
  initialState
);

export default login;
