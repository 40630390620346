import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import backgroundImage from "assets/img/background2.jpg";

const baseTheme = createTheme({
  backgroundImg: `url(${backgroundImage})`,
  background: "rgba(0, 0, 0, 0.5)",
  color: "#FFFFFF",
  chartTextColor: "#FFFFFF",
  borderColor: "#b6c2c9",
  titleColor: "#e3e3e3",
  axisBarColor: "#FFFFFF",
  axisTextBarColor: "#FFFFFF",
  markerColor: "#FFFFFF",
  tACLineColor: "#5cfff5",
  tACBackLineColor: "white",
  generatorLegendColor: "white",
  svgColor: "#FFF",
  svgFilledTextColor: "black"
});

export default baseTheme;
