import { createAsyncThunk } from "@reduxjs/toolkit";
export const getUserRol = createAsyncThunk(
  "obtener-rol-usuario",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.rol.getUserRol(argument);
      //console.log(`getUserRol res : ${JSON.stringify(res.data.data)}`);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getRoles = createAsyncThunk(
  "obtener-roles",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.rol.getRoles();
      // console.log(`getRoles res : ${JSON.stringify(res)}`);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
