/* eslint-disable no-unused-vars */
import { get } from "lodash-es";
import iotmapSelector from "selectors/iotMap";
import { createActions } from "redux-actions";

const actionOptions = {
  prefix: "IOTMAP",
  namespace: "-"
};

export const {
  fetchIotDataStart,
  fetchIotDataSuccess,
  fetchIotDataFail,
  fetchDataChangeLatLon
} = createActions(
  {
    FETCH_DATA_START: undefined,
    FETCH_DATA_SUCCESS: data => ({ data }),
    FETCH_DATA_FAIL: error => ({ error }),
    FETCH_DATA_CHANGE_LAT_LON: data => data
  },
  actionOptions
);

export function fetchIotData(response) {
  return async (dispatch, getState, api) => {
    dispatch(fetchIotDataStart());
    try {
      dispatch(fetchIotDataSuccess(iotmapSelector(response)));
    } catch (error) {
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(fetchIotDataFail(message));
    }
  };
}

export function getLatYlon(dataLatYlon) {
  return dispatch => {
    dispatch(fetchDataChangeLatLon(dataLatYlon));
  };
}

const updateStoreData = (actualData, newData) => {
  return {
    ...actualData,
    ...newData
  };
};
