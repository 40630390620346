/* eslint-disable no-unused-vars */
import { get } from "lodash-es";
import selectorMapVars from "selectors/mapVars";
import { createActions } from "redux-actions";

import {
  setVarsData,
  getFarmIdSelected,
  getFarmHouseIdSelected,
  getFarmInSession,
  getGalponInSession,
  getPlantelInSession,
  getGwTokenInSession
} from "utils/localStorageHelper";

import { updateUpdateTime } from "store/general/actions";

var moment = require("moment");
require("moment/locale/es");

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "VARS",
  namespace: "-"
};

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail,
  updateSocketData,
  updateScreen
} = createActions(
  {
    FETCH_DATA_START: undefined,
    FETCH_DATA_SUCCESS: data => ({ data }),
    FETCH_DATA_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error }),
    UPDATE_SOCKET_DATA: data => ({ data }),
    UPDATE_SCREEN: data => ({ data })
  },
  actionOptions
);

export function updateVarsScreen(data) {
  return (dispatch, getState, api) => {
    dispatch(updateScreen(data));
  };
}

export function updateVarsStoreData(data) {
  return async (dispatch, getState, api) => {
    // console.log(`updateVarsStoreData ${JSON.stringify(data)}`);
    dispatch(fetchDataStart());
    setVarsData(data);
    dispatch(fetchDataSuccess(selectorMapVars(data.mediciones)));
  };
}

export function initializeVarsStoreData() {
  return async (dispatch, getState, api) => {
    dispatch(initializeStoreDataStart());
    dispatch(updateSocketData());
    // console.log(`initializeVarsStoreData`);
    try {
      if (
        getFarmInSession() !== null &&
        getGalponInSession() !== null &&
        getGwTokenInSession() !== null &&
        getPlantelInSession()
      ) {
        const galponId = getGalponInSession();
        const farmId = getFarmInSession();
        const gwtoken = getGwTokenInSession();
        // console.log(`galponId, farmId : ${galponId} ${farmId}`);
        const response = await api.vars.getData(gwtoken);
        let data = response.data && response.data.data;
        // console.log(`response data : ${JSON.stringify(data)}`);

        // let data =
        // response.data &&
        // response.data.data &&
        // response.data.data[0] &&
        // response.data.data[0].mediciones;
        {
          /**
        let horamuestra =
          response.data &&
          response.data.data &&
          response.data.data[0] &&
          response.data.data[0].horamuestra;
       */
        }
        // let matchedGalpon = response?.data?.data?.filter(
        //   info => info.galponId === +galponId
        // );
        // let horamuestra = matchedGalpon[0]?.horamuestra;
        // setUpdateTime(
        //   horamuestra ? moment(horamuestra).format("YYYY-MM-DD H:mm") : null
        // );
        // dispatch(
        //   updateUpdateTime(
        //     horamuestra ? moment(horamuestra).format("YYYY-MM-DD H:mm") : null
        //   )
        // );

        setVarsData(data);
        dispatch(fetchDataSuccess(selectorMapVars(data)));
      }
    } catch (error) {
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(initializeStoreDataFail(message));
    }
  };
}
