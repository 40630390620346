import { createSlice } from "@reduxjs/toolkit";
import { getTypeVar } from "./thunk";
export const rolSlice = createSlice({
  name: "typeVariable",
  initialState: {
    status: "idle",
    getTypeVarStatus: "idle",
    getTypeVarError: null,
    selectedTypeVar: {}
  },
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getTypeVar.pending, (state, action) => {
      state.getTypeVarStatus = "loading";
    });
    builder.addCase(getTypeVar.fulfilled, (state, action) => {
      state.getTypeVarStatus = "succeeded";
      state.selectedTypeVar = action.payload;
    });
    builder.addCase(getTypeVar.rejected, (state, action) => {
      state.getTypeVarStatus = "failed";
      state.getTypeVarError = action.error.message;
    });
  }
});

export default rolSlice.reducer;
