import { axiosInstance } from "./api";

export default {
  getPlantelesByFarm: farmId => {
    return axiosInstance.get(
      "/plantel?$sort[id]=-1&$limit=100&empresaId=" +
        sessionStorage.getItem("empresa") +
        "&granjaId=" +
        farmId
    );
  },
  getAllPlanteles: () => {
    return axiosInstance.get(
      "/plantel?$sort[id]=-1&empresaId=" + sessionStorage.getItem("empresa")
    );
  },
  getActiveAlarmsGroupByPlanteles: () => {
    return axiosInstance.get("/current-alerts");
  },
  getPlantelById: id => axiosInstance.get(`/plantel/${id}`),
  createFarmHouses: data => axiosInstance.post("/plantel", data),
  deletePlantel: data => axiosInstance.delete(`/plantel/${data.id}`),
  updatePlantel: ({ id, data }) => axiosInstance.put(`/plantel/${id}`, data)
};
