import { lazy } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CampaignIcon from "@mui/icons-material/Campaign";
import DescriptionIcon from "@mui/icons-material/Description";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryIcon from "@mui/icons-material/History";
import { Roles } from "utils/helpers";

const VarConditionsContainer = lazy(() => import("containers/pages/vars/vars"));

const AlertsHistory = lazy(() => import("components/pages/alertsHistory"));
const JobsContainer = lazy(() => import("containers/pages/jobs/jobs"));

const ReportsContainer = lazy(() => import("containers/pages/reports/reports"));

const IotMapContainer = lazy(() => import("containers/pages/iotMap/iotMap"));

const Logout = lazy(() => import("containers/pages/logout/Logout"));

const Stats = lazy(() => import("containers/pages/vars/gralStats/Stats"));

const ProcessResultsPage = lazy(() =>
  import("components/pages/ProcessResults")
);

const ProcessControlPage = lazy(() =>
  import("components/pages/processControl")
);

const AlarmPanelPage = lazy(() => import("components/pages/alarmPanel"));

const ActiveAlarmsPage = lazy(() => import("components/pages/activeAlarms"));

const Config = lazy(() => import("components/pages/configs"));

const routes = [
  {
    path: "/iotMap",
    name: "Granjas",
    icon: RoomOutlinedIcon,
    component: IotMapContainer,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Admin, Roles.Monitor, Roles.Supervisor, Roles.Usuario]
  },
  {
    path: "/vars",
    name: "Variables",
    icon: AnalyticsIcon,
    component: VarConditionsContainer,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Usuario, Roles.Monitor, Roles.Supervisor]
  },
  {
    path: "/processResults",
    name: "Resultado del Proceso",
    icon: AnalyticsIcon,
    component: ProcessResultsPage,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Monitor, Roles.Supervisor, Roles.Usuario]
  },
  {
    path: "/generalStats",
    name: "Control del Proceso",
    icon: AnalyticsIcon,
    component: ProcessControlPage,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Monitor, Roles.Supervisor, Roles.Usuario]
  },
  {
    path: "/controller",
    name: "Alarmas del Controlador",
    icon: NotificationsIcon,
    component: AlarmPanelPage,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Monitor, Roles.Supervisor, Roles.Usuario]
  },
  {
    path: "/active-alerts",
    name: "Alertas Activas",
    icon: NotificationsActiveIcon,
    component: ActiveAlarmsPage,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Monitor, Roles.Supervisor, Roles.Usuario]
  },
  {
    path: "/jobs",
    name: "Historial de Escritura",
    icon: HistoryIcon,
    component: JobsContainer,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Monitor, Roles.Supervisor, Roles.Usuario]
  },
  {
    path: "/alerts",
    name: "Historial de Alertas",
    icon: CampaignIcon,
    component: AlertsHistory,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Monitor, Roles.Supervisor, Roles.Usuario]
  },
  {
    externalUrl:
      "https://lookerstudio.google.com/reporting/8ca3bc7c-c924-4114-9d6e-519646b42269",
    name: "Reportes",
    icon: DescriptionIcon,
    component: ReportsContainer,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Monitor, Roles.Supervisor]
  },
  {
    path: "/config",
    name: "Configuraciones",
    icon: SettingsIcon,
    component: Config,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Admin, Roles.Monitor]
  },
  {
    path: "/login",
    name: "Logout",
    icon: ExitToAppIcon,
    component: Logout,
    loggedIn: false,
    hasSubmenu: false,
    permissions: [Roles.Admin, Roles.Monitor, Roles.Supervisor, Roles.Usuario]
  }
];

export default routes;
