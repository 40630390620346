import { createSlice } from "@reduxjs/toolkit";
import { getAlarmDataByGalponId, updateAlarmData } from "./thunks";
// import { store } from "store/store";

export const configAlarmsSlice = createSlice({
  name: "configAlarms",
  initialState: {
    alarms: [],
    alarmData: null,
    status: "idle",
    updateStatus: "idle",
    error: null
  },
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getAlarmDataByGalponId.pending, state => {
      state.status = "loading";
      state.alarmData = null;
    });
    builder.addCase(getAlarmDataByGalponId.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.alarmData = action.payload[0];
    });
    builder.addCase(getAlarmDataByGalponId.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.alarmData = null;
    });
    builder.addCase(updateAlarmData.pending, state => {
      state.updateStatus = "loading";
      state.alarmData = null;
    });
    builder.addCase(updateAlarmData.fulfilled, (state, action) => {
      state.updateStatus = "succeeded";
      state.alarmData = action.payload[0];
    });
    builder.addCase(updateAlarmData.rejected, (state, action) => {
      state.updateStatus = "failed";
      state.error = action.error.message;
      state.alarmData = null;
    });
  }
});

// Action creators are generated for each case reducer function
export default configAlarmsSlice.reducer;
