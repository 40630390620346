import { connect } from "react-redux";
import PropTypes, { element } from "prop-types";
import { useEffect } from "react";
import { getAuthToken } from "utils/auth.js";
import { useHistory } from "react-router-dom";
import { updateVarsStoreData } from "store/vars/actions";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";
import auth from "@feathersjs/authentication-client";
import { useSelector, useDispatch } from "react-redux";
import { VIEWS } from "../../../components/pages/vars/constants";
import { variableSlice } from "../../../store/slices/variable/variableSlice";

import _ from "lodash";
const host_socket = `${process.env.REACT_APP_API_HOST}`;
const socket = io(host_socket, {
  transports: ["polling"]
});

const app = feathers();
app.configure(
  socketio(socket, {
    timeout: 20000
  })
);
app.configure(auth());
const token = getAuthToken();

const mapDispatchToProps = dispatch => ({
  updateVarsStoreData: data => dispatch(updateVarsStoreData(data))
});

function DataControllerContainer(props) {
  const { updateVariable, updateGroupData } = variableSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();
  const { view, variable, groupData } = useSelector(state => state.variable);

  const defaultViewUpdate = (response, type) => {
    const aux = {};
    aux[`${type}`] = response?.data[0];
    const newState = {
      ...variable,
      ...(variable?.[`${type}`]?.gwtoken === response?.data[0].gwtoken
        ? aux
        : {})
    };
    dispatch(updateVariable(newState));
  };

  const groupViewUpdate = (response, type) => {
    const gwtoken = response?.data[0].gwtoken;
    const groupDataToUpdate = { ...groupData };
    const elementToUpdate = {
      ...groupDataToUpdate[`${gwtoken}`]
    };
    if (Object.keys(elementToUpdate ?? {}).length) {
      elementToUpdate[`${type}`] = response?.data[0];
      groupDataToUpdate[`${gwtoken}`] = elementToUpdate;
      dispatch(updateGroupData({ ...groupDataToUpdate }));
    }
  };
  const RTData30s = response => {
    if (view === VIEWS.DEFAULT) {
      defaultViewUpdate(response, "segundos");
    }
    if (view === VIEWS.GROUP) {
      groupViewUpdate(response, "segundos");
    }
  };
  const RTData1m = response => {
    if (view === VIEWS.DEFAULT) {
      defaultViewUpdate(response, "minutos");
    }
    if (view === VIEWS.GROUP) {
      groupViewUpdate(response, "minutos");
    }
  };

  const RTData1h = response => {
    if (view === VIEWS.DEFAULT) {
      defaultViewUpdate(response, "hora");
    }
    if (view === VIEWS.GROUP) {
      groupViewUpdate(response, "hora");
    }
  };

  const RTData1d = response => {
    if (view === VIEWS.DEFAULT) {
      defaultViewUpdate(response, "dia");
    }
    if (view === VIEWS.GROUP) {
      groupViewUpdate(response, "dia");
    }
  };

  useEffect(() => {
    if (token !== null && token !== undefined) {
      // console.log("socket.on connect ");
      socket.on("connect", () => {
        socket.emit(
          "create",
          "authentication",
          {
            strategy: "jwt",
            accessToken: getAuthToken()
          },
          function(error, newAuthResult) {
            if (error !== null && error !== undefined) {
              sessionStorage.removeItem("loggedin");
              history.push("/login");
            }
          }
        );
      });
    }

    //props.initializeVarsStoreData();

    // app
    //   .service("agrodataupdate")
    //   .on("agroDataUpdate", response => getAgroUpdateData(response));
    // app
    //   .service("agrodata-30-s")
    //   .on("RTData30s", response => RTData30s(response));

    // app.service("agrodata-1-m").on("RTData1m", response => RTData1m(response));
    // app.service("agrodata-1-h").on(" RTData1h", response => RTData1h(response));
    // app.service("agrodata-1-d").on("RTData1d", response => RTData1d(response));

    // return () => {
    //   app.service("agrodata-30-s").removeListener("RTData30s");
    //   app.service("agrodata-1-m").removeListener("RTData1m");
    //   app.service("agrodata-1-h").removeListener("RTData1h");
    //   app.service("agrodata-1-d").removeListener("RTData1d");
    // };
  });

  return null;
}

DataControllerContainer.propTypes = {
  getData: PropTypes.func
};

export default connect(
  null,
  mapDispatchToProps
)(DataControllerContainer);
