import { axiosInstance } from "./api";

export default {
  getAgrodata_30_sByGalpon: gwtoken => {
    return axiosInstance.get(
      `agrodata-30-s?$limit=1&$sort[horamuestra]=-1&gwtoken[$in][]=${gwtoken}`
    );
  },
  getAgrodata_1_mByGalpon: gwtoken => {
    return axiosInstance.get(
      `agrodata-1-m?$limit=1&$sort[horamuestra]=-1&gwtoken[$in][]=${gwtoken}`
    );
  },
  getAgrodata_1_hByGalpon: gwtoken => {
    return axiosInstance.get(
      `agrodata-1-h?$limit=1&$sort[horamuestra]=-1&gwtoken[$in][]=${gwtoken}`
    );
  },
  getAgrodata_1_dByGalpon: gwtoken => {
    return axiosInstance.get(
      `agrodata-1-d?$limit=1&$sort[horamuestra]=-1&gwtoken[$in][]=${gwtoken}`
    );
  },
  getAgrodata_ac_byGalpon: gwtoken => {
    return axiosInstance.get(
      `agrodata-ac?$limit=1&$sort[horamuestra]=-1&gwtoken[$in][]=${gwtoken}`
    );
  },
  getVariablesByGalpon: gwtoken => {
    return axiosInstance.get("datastore?$lastupdate=true&gwtoken=" + gwtoken);
  },
  getVistaVariablesByGalpon: gwtoken => {
    return axiosInstance.get("dsviews?$vvctrlproc=true&gwtoken=" + gwtoken);
  },
  getInicioCampaniaByGalpon: gwtoken => {
    return axiosInstance.get(
      `log-rel-version-ic?$limit=1&$sort[corr]=-1&gwtoken[$in][]=${gwtoken}`
    );
  }
  // getVariablesByPlantel: plantelId => {
  //   return axiosInstance.get(
  //     "datastore?$getAllVarsProcesoByPlantel=true&plantelId=" + plantelId
  //   );
  // }
};
