import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "d3";
// import { store } from "store/store";
import {
  getFarms,
  createGranja,
  deleteFarm,
  getFarm,
  updateSelectedFarm
} from "./thunks";

export const farmSlice = createSlice({
  name: "farm",
  initialState: {
    farms: [],
    selectedFarm: {},
    selectedFarmStatus: "idle",
    status: "idle",
    deleteStatus: "idle",
    error: null,
    farmSelectedId: null,
    updateSelectedFarmStatus: "idle"
  },
  reducers: {
    selectFarm: (state, action) => {
      state.farmSelectedId = action.payload;
    }
  },

  extraReducers: builder => {
    builder.addCase(getFarms.pending, (state, action) => {
      state.status = "loading";
      state.farms = [];
    });
    builder.addCase(getFarms.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.farms = action.payload;
      state.updateSelectedFarmStatus = "idle";
    });
    builder.addCase(getFarms.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.farms = [];
    });
    builder.addCase(createGranja.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createGranja.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.farms = [...state.farms, action.payload];
    });
    builder.addCase(createGranja.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.farms = [];
    });
    builder.addCase(deleteFarm.pending, (state, action) => {
      state.deleteStatus = "loading";
    });
    builder.addCase(deleteFarm.fulfilled, (state, action) => {
      state.deleteStatus = "succeeded";
    });
    builder.addCase(deleteFarm.rejected, (state, action) => {
      state.deleteStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getFarm.pending, (state, action) => {
      state.selectedFarmStatus = "loading";
    });
    builder.addCase(getFarm.fulfilled, (state, action) => {
      state.selectedFarmStatus = "succeeded";
      state.selectedFarm = action.payload;
    });
    builder.addCase(getFarm.rejected, (state, action) => {
      state.selectedFarmStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(updateSelectedFarm.pending, (state, action) => {
      state.updateSelectedFarmStatus = "loading";
    });
    builder.addCase(updateSelectedFarm.fulfilled, (state, action) => {
      state.updateSelectedFarmStatus = "succeeded";
    });
    builder.addCase(updateSelectedFarm.rejected, (state, action) => {
      state.updateSelectedFarmStatus = "failed";
      state.error = action.error.message;
    });
  }
});

// Action creators are generated for each case reducer function
export const { selectFarm } = farmSlice.actions;
export default farmSlice.reducer;
