import { axiosInstance } from "./api";

export default {
  getFarmsByCompany: () => {
    return axiosInstance.get(
      "/granja?$sort[id]=-1&$limit=100&empresaId=" +
        sessionStorage.getItem("empresa")
    );
  },
  getFarm: id => axiosInstance.get(`/granja/${id}`),
  createFarm: data => axiosInstance.post("/granja", data),
  deleteFarm: data => axiosInstance.delete(`/granja/${data.id}`),
  updateFarm: ({ id, data }) => axiosInstance.put(`/granja/${id}`, data)
};
