// import { combineReducers } from "redux";
import { combineReducers } from "@reduxjs/toolkit";
import modal from "./modal/reducer";
// import generalConditions from "store/generalConditions/reducer";
import vars from "store/vars/reducer";
import settings from "store/settings/reducer";
import login from "store/login/reducer";
import general from "store/general/reducer";
import iotMap from "store/iotMap/reducer";
import alarms from "store/alarms/reducer";
import farmSlice from "./slices/farm/farmSlice";
import plantelSlice from "./slices/plantel/plantelSlice";
import galponSlice from "./slices/galpon/galponSlice";
import variableSlice from "./slices/variable/variableSlice";
import sensorSlice from "./slices/sensors/sensorSlice";
import timelineSlice from "./slices/timeline/timelineSlice";
import usersSlice from "./slices/users/usersSlice";
import rolSlice from "./slices/rol/rolSlice";
import typeVariableSlice from "./slices/typeVariable/typeVariableSlice";
import configAlarms from "./slices/configAlarms/configAlarms";

const combinedReducer = combineReducers({
  modal,
  // generalConditions,
  vars,
  settings,
  login,
  general,
  iotMap,
  alarms,
  farm: farmSlice,
  plantel: plantelSlice,
  galpon: galponSlice,
  variable: variableSlice,
  sensors: sensorSlice,
  timeline: timelineSlice,
  users: usersSlice,
  rol: rolSlice,
  typeVariable: typeVariableSlice,
  configAlarms: configAlarms
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer;
