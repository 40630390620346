import { axiosAnonInstance } from "./api.js";

export default {
  LoginAuthentication: (mail, password) =>
    axiosAnonInstance.post(process.env.REACT_APP_API_AUTHENTICATION, {
      strategy: "local", //process.env.REACT_APP_API_STRATEGY,
      mail: mail, // email,//process.env.REACT_APP_API_USER,
      password: password //password//process.env.REACT_APP_API_PASS
    })
};
