import auth from "@feathersjs/authentication-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";

const host_socket = `${process.env.REACT_APP_API_HOST}`;
const socket = io(host_socket, {
  transports: ["polling"]
});

const feathersClient = feathers();
feathersClient.configure(
  socketio(socket, {
    timeout: 20000
  })
);
feathersClient.configure(auth());

export { feathersClient, socket };
