import { lazy } from "react";

const RecoveryPage = lazy(() =>
  import("./components/pages/recoverPassword/recoveryPage")
);
const RecoveryLinkPage = lazy(() =>
  import("./components/pages/recoverPassword/recoveryLinkPage")
);
const RecoveryNewPass = lazy(() =>
  import("./components/pages/recoverPassword/recoveryNewPass")
);
const RecoverySuccessSend = lazy(() =>
  import("./components/pages/recoverPassword/recoverySuccessSend")
);
const ResetPasswordSuccess = lazy(() =>
  import("./components/pages/recoverPassword/resetPasswordSuccess")
);
const Login = lazy(() => import("containers/pages/login/Login"));

const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/recovery",
    name: "Recovery",
    component: RecoveryPage
  },
  {
    path: "/link",
    name: "Recovery",
    component: RecoveryLinkPage
  },
  {
    path: "/newpass",
    name: "Recovery",
    component: RecoveryNewPass
  },
  {
    path: "/sentemail",
    name: "Recovery",
    component: RecoverySuccessSend
  },
  {
    path: "/success",
    name: "Recovery",
    component: ResetPasswordSuccess
  }
];

export default authRoutes;
