/* eslint-disable no-unused-vars */
import { getLoggedIn, setLoggedIn } from "utils/localStorageHelper";
import { createActions } from "redux-actions";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "LOGIN", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

// Los nombres de las variables siempre se pasan de snake_case a camelCase
export const { updateLoginState } = createActions(
  {
    UPDATE_LOGIN_STATE: data => ({ data })
  },
  actionOptions
);

export function getLoginState() {
  return async dispatch => {
    dispatch(getLoggedIn());
  };
}

export function setLoginState(state) {
  return async dispatch => {
    setLoggedIn(state);
    dispatch(setLoggedIn(state));
  };
}
