/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import classNames from "classnames";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import { NavLink, useLocation } from "react-router-dom";
import styles from "assets/jss/components/menuItemStyle.js";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(styles);

const NavMenuItem = props => {
  const classes = useStyles();

  const { onClick, path, children, externalUrl } = props;

  let location = useLocation();

  function activeRoute(routeName) {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  var activePro = " ";

  var listItemClasses;
  listItemClasses = classNames({
    [" " + classes["blue"]]: activeRoute(path)
  });

  if (externalUrl) {
    return (
      <a
        href={externalUrl}
        className={classes.item}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItem
          className={classes.itemLink + listItemClasses}
          children={children}
          onClick={onClick}
        />
      </a>
    );
  }

  if (!path || typeof path !== "string") {
    return (
      <ListItem
        button
        className={classes.itemLink + listItemClasses}
        children={children}
      />
    );
  }

  return (
    <NavLink
      to={path}
      className={activePro + classes.item}
      activeClassName="active"
    >
      <ListItem
        button
        className={classes.itemLink + listItemClasses}
        children={children}
        to={path}
        onClick={onClick}
      />
    </NavLink>
  );
};

const MenuItem = props => {
  const {
    name,
    icon,
    hasSubmenu,
    subMenuList = [],
    path,
    rtlName,
    rtlActive,
    submenu,
    setMobileOpen,
    externalUrl
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  let location = useLocation();

  function activeRoute(routeName) {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  const whiteFontClasses = classNames({
    [" " + classes.whiteFont]: activeRoute(path)
  });

  function handleClick() {
    setMobileOpen(false);
  }

  const MenuItemRoot = (
    <NavMenuItem path={path} onClick={handleClick} externalUrl={externalUrl}>
      {/* Display an icon if any */}
      {icon ? (
        typeof icon === "string" ? (
          <Icon
            className={classNames(classes.itemIcon, whiteFontClasses, {
              [classes.itemIconRTL]: rtlActive
            })}
          >
            {icon}
          </Icon>
        ) : (
          <props.icon
            className={classNames(classes.itemIcon, whiteFontClasses, {
              [classes.itemIconRTL]: rtlActive
            })}
          />
        )
      ) : null}
      {hasSubmenu && !open && (
        <IconExpandMore
          className={classNames(classes.itemIconExpand, whiteFontClasses, {
            [classes.itemIconExpandRTL]: rtlActive
          })}
        />
      )}
      {hasSubmenu && open && (
        <IconExpandLess
          className={classNames(classes.itemIconExpand, whiteFontClasses, {
            [classes.itemIconExpandRTL]: rtlActive
          })}
        />
      )}
      <ListItemText
        primary={rtlActive ? rtlName : name}
        className={classNames(classes.itemText, whiteFontClasses, {
          [classes.itemTextRTL]: rtlActive,
          [classes.subMenuText]: submenu
        })}
        disableTypography={true}
      />
    </NavMenuItem>
  );

  const MenuItemChildren = hasSubmenu ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" className={classes.list}>
        {subMenuList.map((submenu, index) => (
          <MenuItem {...submenu} key={index} submenu={true} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default MenuItem;
