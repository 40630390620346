import { createAsyncThunk } from "@reduxjs/toolkit";
export const createUsuario = createAsyncThunk(
  "create-usuario",
  async (argument, thunkAPI) => {
    try {
      // console.log(`Guardando en users ${JSON.stringify(argument)}`);
      const res = await thunkAPI.extra.users.createUser(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "create-usuario",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.users.deleteUser(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getUser = createAsyncThunk(
  "obtener-usuario",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.users.getUserById(argument);
      // console.log(`getUser ${JSON.stringify(res.data)}`);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "obtener-usuarios",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.users.getUsers();
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const updateSelectedUser = createAsyncThunk(
  "actualizar-usuario",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.users.updateUser(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
