import { createAsyncThunk } from "@reduxjs/toolkit";
export const getVariables = createAsyncThunk(
  "get-variables",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getVariablesByGalpon(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getVistaVariables = createAsyncThunk(
  "get-vista-variables",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getVistaVariablesByGalpon(
        argument
      );
      return res.data.data[0];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const getDsViews = createAsyncThunk(
  "ds_views",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getVistaVariablesByGalpon(
        argument
      );

      return res.data.data[0];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getInicioCampania = createAsyncThunk(
  "get-inicio-campania",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getInicioCampaniaByGalpon(
        argument
      );
      return res.data.data[0];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAgrodata_30_s = createAsyncThunk(
  "get-agrodata_30_s",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getAgrodata_30_sByGalpon(
        argument
      );
      return res.data.data[0];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAgrodata_1_m = createAsyncThunk(
  "get-agrodata_1_m",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getAgrodata_1_mByGalpon(
        argument
      );
      return res.data.data[0];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAgrodata_1_h = createAsyncThunk(
  "get-agrodata_1_h",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getAgrodata_1_hByGalpon(
        argument
      );
      return res.data.data[0];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAgrodata_1_d = createAsyncThunk(
  "get-agrodata_1_d",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getAgrodata_1_dByGalpon(
        argument
      );
      //console.log(`thunk 1d ${JSON.stringify(res.data.data[0])}`);
      return res.data.data[0];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAgrodata_ac = createAsyncThunk(
  "get-agrodata_ac",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.variable.getAgrodata_ac_byGalpon(
        argument
      );
      return res.data.data[0];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
