import { axiosInstance } from "./api";
export default {
  getAlarms: () =>
    axiosInstance.get(
      "/alarma?$sort[granjaId]=-1&$limit=100&empresaId=" +
        sessionStorage.getItem("empresa")
    ),
  createAlarm: alarm => axiosInstance.post("/alarma", alarm),
  deleteAlarm: data => axiosInstance.delete(`/alarma/${data.id}`),
  getAlarmDataByGalponId: data =>
    axiosInstance.get(`/alarma?galponId=${data.galponId}`),
  updateAlarmData: data => axiosInstance.put(`/alarma/${data.id}`, data.obj)
};
