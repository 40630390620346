// Settings

export const getLoggedIn = () => {
  return sessionStorage.getItem("loggedin");
};
export const setLoggedIn = async state => {
  await sessionStorage.setItem("loggedin", state);
};

export const getTheme = () => {
  return sessionStorage.getItem("theme");
};

export const setTheme = async theme => {
  await sessionStorage.setItem("theme", theme);
};

export const setVarsData = async data => {
  await sessionStorage.setItem("varsData", JSON.stringify(data));
};

export const getVarsData = () => {
  try {
    let data = sessionStorage.getItem("varsData");
    if (data == null) return null;
    return JSON.parse(data);
  } catch {
    return null;
  }
};

// ----------------------------------//
// --- DATOS USUARIO -----------//
// ----------------------------------//

// FarmName

export const getFarmName = () => {
  try {
    return sessionStorage.getItem("granjaNombre");
  } catch {
    return null;
  }
};

export const setFarmName = async value => {
  await sessionStorage.setItem("granjaNombre", value);
};

// FarmId

export const getFarmInSession = () => {
  try {
    return sessionStorage.getItem("farmId");
  } catch {
    return null;
  }
};

export const getFarmId = () => {
  try {
    return sessionStorage.getItem("granjaId");
  } catch {
    return null;
  }
};

export const setFarmId = async value => {
  await sessionStorage.setItem("granjaId", value);
};

// FarmHouseName

export const getPlantelInSession = () => {
  try {
    return sessionStorage.getItem("plantelId");
  } catch {
    return null;
  }
};

export const getFarmHouseName = () => {
  try {
    return sessionStorage.getItem("nombre");
  } catch {
    return null;
  }
};

export const setFarmHouseName = async value => {
  await sessionStorage.setItem("nombre", value);
};

//FarmHouseId

export const getFarmHouseId = () => {
  try {
    return sessionStorage.getItem("galponId");
  } catch {
    return null;
  }
};

export const setFarmHouseId = async value => {
  await sessionStorage.setItem("galponId", value);
};

// ----------------------------------//
// --- DATOS SELECCIONADOS -----------//
// ----------------------------------//

// FarmName

export const getFarmNameSelected = () => {
  try {
    return sessionStorage.getItem("farmNameSelected");
  } catch {
    return null;
  }
};

export const setFarmNameSelected = async value => {
  await sessionStorage.setItem("farmNameSelected", value);
};

// FarmId

export const getFarmIdSelected = () => {
  try {
    return sessionStorage.getItem("farmIdSelected");
  } catch {
    return null;
  }
};

export const setFarmIdSelected = async value => {
  await sessionStorage.setItem("farmIdSelected", value);
};

// FarmHouseName

export const getFarmHouseNameSelected = () => {
  try {
    return sessionStorage.getItem("farmHouseNameSelected");
  } catch {
    return null;
  }
};

export const setFarmHouseNameSelected = async value => {
  await sessionStorage.setItem("farmHouseNameSelected", value);
};

//FarmHouseId

export const getFarmHouseIdSelected = () => {
  try {
    return sessionStorage.getItem("farmHouseIdSelected");
  } catch {
    return null;
  }
};

export const setFarmHouseIdSelected = async value => {
  await sessionStorage.setItem("farmHouseIdSelected", value);
};

// GalponId
export const setGalponInSession = value => {
  sessionStorage.setItem("galponSelectedId", value);
};

export const getGalponInSession = () => {
  try {
    return sessionStorage.getItem("galponSelectedId");
  } catch {
    return null;
  }
};

// GalponGwToken
export const setGwTokenInSession = value => {
  sessionStorage.setItem("galponSelectedGwToken", value);
};

export const getGwTokenInSession = () => {
  try {
    return sessionStorage.getItem("galponSelectedGwToken");
  } catch {
    return null;
  }
};

export const setHistoryFilters = filters => {
  const values = JSON.stringify(filters);
  sessionStorage.setItem("historyFilters", values);
};

export const getHistoryFilters = () => {
  try {
    const filters = sessionStorage.getItem("historyFilters");
    return JSON.parse(filters) ?? {};
  } catch {
    return {};
  }
};

export const clearHistoryFilters = () => {
  sessionStorage.removeItem("historyFilters");
};
