import { createSlice } from "@reduxjs/toolkit";
import { getTimelinesAlertsByGalpon } from "./thunks";

export const timelineSlice = createSlice({
  name: "timeline",
  initialState: {
    alerts: [],
    status: "idle",
    error: null
  },
  reducers: {
    selectGalpon: (state, action) => {
      state.galponSelectedId = action.payload;
    }
  },

  extraReducers: builder => {
    builder.addCase(getTimelinesAlertsByGalpon.pending, (state, action) => {
      state.status = "loading";
      state.alerts = [];
      // console.log(
      //   "🚀 ~ file: galponSlice.js ~ line 21 ~ builder.addCase ~ status",
      //   action
      // );
    });
    builder.addCase(getTimelinesAlertsByGalpon.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.alerts = action.payload[0]?.alerts;
      // console.log(
      //   "🚀 ~ file: galponSlice.js ~ line 27 ~ builder.addCase ~ galpones",
      //   action
      // );
    });
    builder.addCase(getTimelinesAlertsByGalpon.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.alerts = [];
      // console.log(
      //   "🚀 ~ file: galponSlice.js ~ line 33 ~ builder.addCase ~ galpones",
      //   action
      // );
    });
  }
});

// Action creators are generated for each case reducer function
export const { selectGalpon } = timelineSlice.actions;
export default timelineSlice.reducer;
