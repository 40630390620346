import { configureStore } from "@reduxjs/toolkit";
import apiService from "../api/api";
import rootReducer from "./reducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: apiService
      },
      serializableCheck: false
    })
});

// configureStore(
//   reducers,
//   undefined,
//   composeWithDevTools(applyMiddleware(thunk.withExtraArgument(apiService)))
// );
