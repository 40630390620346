import { createSlice, current } from "@reduxjs/toolkit";
import {
  getPlanteles,
  createPlantel,
  deletePlantel,
  getPlantel,
  getAllPlanteles,
  getAllActiveAlarmsPlanteles,
  updateSelectedPlantel
} from "./thunks";

export const plantelSlice = createSlice({
  name: "plantel",
  initialState: {
    planteles: [],
    planteles_active_alarms: [],
    selectedPlantel: {},
    selectedPlantelStatus: "idle",
    plantelSelectedId: null,
    status: "idle",
    deleteStatus: "idle",
    error: null,
    updatePlantelStatus: "idle"
  },
  reducers: {
    selectPlantel: (state, action) => {
      state.plantelSelectedId = action.payload;
    },
    addActiveAlarmsPlantelesReducer: (state, action) => {
      state.planteles_active_alarms = action.payload;
    },
    updateActiveAlarmsPlantelesReducer: (state, action) => {
      // console.log("state", current(state.planteles_active_alarms));
      const newPlanteles = action.payload;
      const oldPlanteles = current(state.planteles_active_alarms);
      const resultPlanteles = oldPlanteles.map(item => {
        // console.log(item);
        for (let index = 0; index < newPlanteles.length; index++) {
          const element = newPlanteles[index];
          // console.log("element", element);
          if (item.id === element.id) {
            const newGalpones = item.galpones.map(galpon => {
              // console.log("galpon", galpon);
              for (let index = 0; index < element.galpones.length; index++) {
                const newGalpon = element.galpones[index];
                // console.log("newGalpon", newGalpon);
                if (newGalpon.id === galpon.id) {
                  return {
                    name: newGalpon.nombre,
                    id: newGalpon.id,
                    gwtoken: galpon.gwtoken,
                    alarma_activa_tipo: newGalpon.alarma_activa_tipo,
                    reiteracion: newGalpon.reiteracion
                  };
                }
              }
              return galpon;
            });
            // console.log("newGalpones", newGalpones);
            const newObj = {
              id: element.id,
              nombre: element.nombre,
              galpones: newGalpones
            };
            // console.log("newObj", newObj);
            return newObj;
          }
        }
        return item;
      });
      // console.log("res", resultPlanteles);
      state.planteles_active_alarms = resultPlanteles;
    }
  },

  extraReducers: builder => {
    builder.addCase(getPlanteles.pending, (state, action) => {
      state.status = "loading";
      state.planteles = [];
    });
    builder.addCase(getPlanteles.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.planteles = action.payload;
      state.updatePlantelStatus = "idle";
    });
    builder.addCase(getPlanteles.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.planteles = [];
    });
    builder.addCase(getAllPlanteles.pending, (state, action) => {
      state.status = "loading";
      state.planteles = [];
    });
    builder.addCase(getAllPlanteles.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.planteles = action.payload;
      state.updatePlantelStatus = "idle";
    });
    builder.addCase(getAllPlanteles.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.planteles = [];
    });
    builder.addCase(getAllActiveAlarmsPlanteles.pending, (state, action) => {
      state.status = "loading";
      state.planteles_active_alarms = [];
    });
    builder.addCase(getAllActiveAlarmsPlanteles.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.planteles_active_alarms = action.payload;
      state.updatePlantelStatus = "idle";
    });
    builder.addCase(getAllActiveAlarmsPlanteles.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.planteles_active_alarms = [];
    });
    builder.addCase(createPlantel.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createPlantel.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.planteles = [...state.planteles, action.payload];
    });
    builder.addCase(createPlantel.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.planteles = [];
    });
    builder.addCase(deletePlantel.pending, (state, action) => {
      state.deleteStatus = "loading";
    });
    builder.addCase(deletePlantel.fulfilled, (state, action) => {
      state.deleteStatus = "succeeded";
    });
    builder.addCase(deletePlantel.rejected, (state, action) => {
      state.deleteStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getPlantel.pending, (state, action) => {
      state.selectedPlantelStatus = "loading";
    });
    builder.addCase(getPlantel.fulfilled, (state, action) => {
      state.selectedPlantelStatus = "succeeded";
      state.selectedPlantel = action.payload;
    });
    builder.addCase(getPlantel.rejected, (state, action) => {
      state.selectedPlantelStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(updateSelectedPlantel.pending, (state, action) => {
      state.updatePlantelStatus = "loading";
    });
    builder.addCase(updateSelectedPlantel.fulfilled, (state, action) => {
      state.updatePlantelStatus = "succeeded";
    });
    builder.addCase(updateSelectedPlantel.rejected, (state, action) => {
      state.updatePlantelStatus = "failed";
      state.error = action.error.message;
    });
  }
});

// Action creators are generated for each case reducer function
export const {
  selectPlantel,
  updateActiveAlarmsPlantelesReducer,
  addActiveAlarmsPlantelesReducer
} = plantelSlice.actions;
export default plantelSlice.reducer;
