/* eslint-disable no-unused-vars */
import { get } from "lodash-es";
import { createActions } from "redux-actions";

const actionOptions = {
  prefix: "ALARMS",
  namespace: "-"
};

export const {
  fetchAlarmsDataStart,
  fetchAlarmsDataSuccess,
  fetchAlarmsDataFail,
  createAlarmAction
} = createActions(
  {
    FETCH_ALARMS_DATA_START: undefined,
    FETCH_ALARMS_DATA_SUCCESS: data => data,
    FETCH_ALARMS_DATA_FAIL: error => ({ error }),
    CREATE_ALARM_ACTION: data => data
  },
  actionOptions
);

export function fetchAlarmsData() {
  return async (dispatch, getState, api) => {
    dispatch(fetchAlarmsDataStart());
    try {
      const response = await api.alarms.getAlarms();
      dispatch(fetchAlarmsDataSuccess({ ...response.data }));
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos de las alarmas."
      );
      dispatch(fetchAlarmsDataFail(message));
    }
  };
}

export function createAlarmFn(data) {
  return async (dispatch, getState, api) => {
    const response = await api.alarms.createAlarm(data);
    dispatch(createAlarmAction({ ...response }));
  };
}
