/* eslint-disable no-unused-vars */
import {
  fetchAlarmsDataStart,
  fetchAlarmsDataSuccess,
  fetchAlarmsDataFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  data: [],
  limit: 100,
  skip: 0,
  total: 1,
  loading: false,
  error: ""
};

const alarms = handleActions(
  new Map([
    [
      fetchAlarmsDataStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchAlarmsDataSuccess,
      (state, action) => ({
        // Mapear correctamente los datos aca
        ...state,
        data: action.payload.data,
        loading: false
      })
    ],
    [
      fetchAlarmsDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ]
  ]),
  initialState
);

export default alarms;
