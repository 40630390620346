export const Roles = {
  Admin: "ADMIN",
  Monitor: "MONITOR",
  Supervisor: "SUPERVISOR",
  Usuario: "USUARIO"
};

export const hasPermission = permissions => {
  return permissions.includes(sessionStorage.getItem("usuarioRol"));
};

export const hasPermissionVarsDetails = () => {
  return [Roles.Monitor].includes(sessionStorage.getItem("usuarioRol"));
};

export const decimalToHours = (decimal = "NA") => {
  if (decimal == "NA") return decimal;
  const time = new Date(0, 0);
  time.setSeconds(+decimal * 60 * 60);
  const timeString = time.toTimeString().slice(0, 8);
  return timeString;
};

export const decimalToMinutesSeconds = floatValue => {
  if (floatValue === "NA") return "NA";
  var minutes = Math.floor(floatValue);
  var seconds = Math.round((floatValue - minutes) * 60);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

export const getChangedValues = (values, initialValues) => {
  const changedValues = {};
  // Loop over the keys in the current values object
  Object.keys(values).forEach(key => {
    // If the current value is different from the initial value, add it to the changedValues object
    if (values[key] !== initialValues[key]) {
      changedValues[key] = values[key];
    }
  });
  return changedValues;
};

export const getFormattedValueIndicator = (value, suffix = "") => {
  if (!value || value == "NA") return "NA";
  return `${value} ${suffix}`;
};

export const formatStringToArray = data => {
  const chunkSize = 1;
  const arr = [];

  for (let i = 0; i < data.length; i += chunkSize) {
    arr.push(data.slice(i, i + chunkSize));
  }
  return arr;
};

export const isObjEmpty = obj => {
  return Object.keys(obj).length === 0;
};

export const deleteObjectDuplicateInArray = data => {
  return data.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      data.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
};

export const mapToGrid = data => {
  const arrayData = data?.data;
  if (!arrayData) return [];
  const uniqueArrayWithId = arrayData.map((x, index) => {
    return { id: index + 1, ...x };
  });
  return uniqueArrayWithId;
};

export const getNumberValue = value => {
  if (isNaN(Number(value))) return 0;
  return Number(value);
};

export const getPercentHeight = percent => {
  return window.innerHeight * (percent / 100);
};

export const sliceArray = (array, length = 6) =>
  array?.length
    ? [array.slice(0, length)].concat(sliceArray(array.slice(length), length))
    : [];
