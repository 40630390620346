import { createSelector } from "reselect";
// import { getWindUnit } from "utils/localStorageHelper";
import { round } from "utils/math";

const vars = createSelector(
  data => data,
  data =>
    typeof data !== "undefined" && data !== null
      ? {
          grow_day: round(data.data.segundos.data[0].mediciones.id_3747, -1),
          in_avg_temp: round(data.in_avg_temp, -1),
          out_temp: round(data.out_temp, -1),
          in_hum: round(data.in_hum, -1),
          curr_CO2: round(data.curr_CO2, -1),
          day_water_cons: round(data.day_water_cons, -1),
          day_feed_cons: round(data.day_feed_cons, -1),
          silo1: round(data.silo1, -1),
          silo2: round(data.silo2, -1),
          curr_cool_curtain: round(data.curr_cool_curtain, -1),
          day_mor: round(data.day_mor, -1),
          total_mor: round(data.total_mor, -1),
          vent_stage: round(data.vent_stage, -1)
        }
      : {
          grow_day: 0,
          in_avg_temp: 0,
          out_temp: 0,
          in_hum: 0,
          curr_CO2: 0,
          day_water_cons: 0,
          day_feed_cons: 0,
          silo1: 0,
          silo2: 0,
          curr_cool_curtain: 0,
          day_mor: 0,
          total_mor: 0,
          vent_stage: 0
        }
);

export default vars;
