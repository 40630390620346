import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import React from "react";
import baseLightTheme from "./baseLightTheme";
import baseDarkTheme from "./baseDarkTheme";
import GlobalStyle from "./globalStyles";

const AppTheme = ({ children }) => {
  const [mode, setMode] = React.useState("light");
  const theme = React.useMemo(
    () => createTheme(mode === "light" ? baseLightTheme : baseDarkTheme),
    [mode]
  );
  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline enableColorScheme /> */}
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default AppTheme;
