import { createSlice } from "@reduxjs/toolkit";
import { deleteUser, getUser, updateSelectedUser, getAllUsers } from "./thunks";

export const userSlice = createSlice({
  name: "users",
  initialState: {
    status: "idle",
    deleteStatus: "idle",
    error: null,
    selectedUserEdit: {},
    selectedUserEditStatus: "idle",
    selectedUserEditError: null,
    updatedUserStatus: "idle",
    users: [],
    getAllUsersStatus: "idle"
  },
  reducers: {},

  extraReducers: builder => {
    builder.addCase(deleteUser.pending, (state, action) => {
      state.deleteStatus = "loading";
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.deleteStatus = "succeeded";
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.deleteStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getUser.pending, (state, action) => {
      state.selectedUserEditStatus = "loading";
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.selectedUserEditStatus = "succeeded";
      state.selectedUserEdit = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.selectedUserEditStatus = "failed";
      state.selectedUserEditError = action.error.message;
    });
    builder.addCase(updateSelectedUser.pending, (state, action) => {
      state.updatedUserStatus = "loading";
    });
    builder.addCase(updateSelectedUser.fulfilled, (state, action) => {
      state.updatedUserStatus = "succeeded";
    });
    builder.addCase(updateSelectedUser.rejected, (state, action) => {
      state.updatedUserStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.getAllUsersStatus = "loading";
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.getAllUsersStatus = "succeeded";
      state.users = action.payload;
      state.selectedUserEditStatus = "idle";
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.getAllUsersStatus = "failed";
      state.error = action.error.message;
    });
  }
});

// Action creators are generated for each case reducer function
export default userSlice.reducer;
