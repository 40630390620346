import { connect } from "react-redux";
import PropTypes from "prop-types";
import ConfigMenu from "components/common/configMenu/configMenu";
import {
  // updateWindUnitStorage,
  // updateCurrentsUnitStorage,
  updateThemeStorage
} from "store/settings/actions";

const mapStateToProps = state => ({
  // unit: state.settings.windUnit,
  // currentsUnit: state.settings.currentsUnit,
  theme: state.settings.theme
});

const mapDispatchToProps = dispatch => ({
  // updateWindUnit: unit => dispatch(updateWindUnitStorage(unit)),
  // updateCurrentsUnit: unit => dispatch(updateCurrentsUnitStorage(unit)),
  updateTheme: theme => dispatch(updateThemeStorage(theme))
});

function ConfigMenuContainer(props) {
  // const handleUnitClick = unit => {
  //   props.updateWindUnit(unit);
  // };

  // const handleCurrentsUnitClick = unit => {
  //   props.updateCurrentsUnit(unit);
  // };

  const handleSwitchTheme = event => {
    let isChecked = event.target.checked;
    props.updateTheme(isChecked ? "light" : "dark");
  };

  return (
    <ConfigMenu
      {...props}
      // handleUnitClick={unit => handleUnitClick(unit)}
      handleSwitchTheme={handleSwitchTheme}
      // handleCurrentsUnitClick={unit => handleCurrentsUnitClick(unit)}
    />
  );
}

ConfigMenuContainer.propTypes = {
  // updateWindUnit: PropTypes.func,
  // updateCurrentsUnit: PropTypes.func,
  updateTheme: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigMenuContainer);
