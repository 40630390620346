import { createSlice } from "@reduxjs/toolkit";
import { getUserRol, getRoles } from "./thunk";
export const rolSlice = createSlice({
  name: "selectedUserRol",
  initialState: {
    status: "idle",
    getUserRolStatus: "idle",
    getUserRolError: null,
    getUserRol: {},
    selectedUserRol: {},
    getRolesStatus: "idle",
    getRolesError: null,
    roles: []
  },
  reducers: {
    selectedRol: (state, action) => {
      state.selectedUserRol = action.payload;
    }
  },

  extraReducers: builder => {
    builder.addCase(getUserRol.pending, (state, action) => {
      state.getUserRolStatus = "loading";
    });
    builder.addCase(getUserRol.fulfilled, (state, action) => {
      state.getUserRolStatus = "succeeded";
      // state.selectedUserRol = action.payload;
      state.getUserRol = action.payload;
    });
    builder.addCase(getUserRol.rejected, (state, action) => {
      state.getUserRolStatus = "failed";
      state.getUserRolError = action.error.message;
    });
    builder.addCase(getRoles.pending, (state, action) => {
      state.getRolesStatus = "loading";
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.getRolesStatus = "succeeded";
      // state.selectedUserRol = action.payload;
      state.roles = action.payload;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.getRolesStatus = "failed";
      state.getRolesError = action.error.message;
    });
  }
});

export const { selectedRol } = rolSlice.actions;
export default rolSlice.reducer;
