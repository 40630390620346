/* eslint-disable no-unused-vars */
import {
  fetchIotDataStart,
  fetchIotDataSuccess,
  fetchIotDataFail,
  fetchDataChangeLatLon,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  data: [],
  loading: false,
  error: "",
  latYlonStart: [],
  isLatYlong: false
};

const vars = handleActions(
  new Map([
    [
      fetchIotDataStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchIotDataSuccess,
      (state, action) => ({
        // Mapear correctamente los datos aca
        ...state,
        data: action.payload.data,
        loading: false
      })
    ],
    [
      fetchIotDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    [
      fetchDataChangeLatLon,
      (state, action) => ({
        ...state,
        latYlonStart: action.payload,
        isLatYlong: true
      })
    ]
  ]),
  initialState
);

export default vars;
