import { createSelector } from "reselect";

const mapIotMap = createSelector(
  data => data,
  data =>
    typeof data !== "undefined" && data !== null && data.total !== 0
      ? {
          places: data.total
          // full: data.Archivo && data.Archivo,
          // thumb: data.Thumbnail && data.Thumbnail,
          // hour: data.hora && data.hora
        }
      : {
          places: []
          // total: 0,
          // full: '',
          // thumb: '',
          // hour: ''
        }
);

export default mapIotMap;
