import { createSlice } from "@reduxjs/toolkit";
import { VIEWS } from "variables/constants";
import {
  getAgrodata_1_d,
  getAgrodata_1_h,
  getAgrodata_1_m,
  getAgrodata_30_s,
  getAgrodata_ac,
  getDsViews,
  getInicioCampania,
  getVariables
} from "./thunks";

export const variableSlice = createSlice({
  name: "variable",
  initialState: {
    view: sessionStorage.getItem("view") ?? VIEWS.DEFAULT,
    variable: {},
    vistaVariable: {},
    inicioCampania: {},
    agrodata_30_s: {},
    agrodata_1_m: {},
    agrodata_1_h: {},
    agrodata_1_d: {},
    agrodata_ac: {},
    dsViews: {},
    groupData: {},
    status: "idle",
    statusAgrodata_30_s: "idle",
    statusAgrodata_1_m: "idle",
    statusAgrodata_1_h: "idle",
    statusAgrodata_1_d: "idle",
    statusAgrodata_ac: "idle",
    statusInicioDeCampania: "idle",
    statusDsViews: "idle",
    changedFormValues: {},
    error: null,
    socketSpinnerLoading: false
  },
  reducers: {
    clearAgrodata: state => {
      state.agrodata_30_s = {};
      state.agrodata_1_m = {};
      state.agrodata_1_h = {};
      state.agrodata_1_d = {};
    },
    setSocketSpinnerLoading: (state, action) => {
      state.socketSpinnerLoading = action.payload;
    },
    updateview: (state, action) => {
      state.view = action.payload;
      sessionStorage.setItem("view", action.payload);
    },
    updateGroupData: (state, action) => {
      state.groupData = action.payload;
    },
    updateVariable: (state, action) => {
      state.variable = action.payload;
    },
    updateAgrodata_30_s: (state, action) => {
      if (
        action.payload.galponSelectedGwToken ===
        action.payload.response?.data[0].gwtoken
      ) {
        state.agrodata_30_s[`${action.payload.galponSelectedGwToken}`] =
          action.payload.response.data[0];
      }
    },
    updateAgrodata_1_m: (state, action) => {
      if (
        action.payload.galponSelectedGwToken ===
        action.payload.response?.data[0].gwtoken
      ) {
        state.agrodata_1_m[`${action.payload.galponSelectedGwToken}`] =
          action.payload.response.data[0];
      }
    },
    updateAgrodata_1_h: (state, action) => {
      if (
        action.payload.galponSelectedGwToken ===
        action.payload.response?.data[0].gwtoken
      ) {
        state.agrodata_1_h[`${action.payload.galponSelectedGwToken}`] =
          action.payload.response.data[0];
      }
    },
    updateAgrodata_1_d: (state, action) => {
      if (
        action.payload.galponSelectedGwToken ===
        action.payload.response?.data[0].gwtoken
      ) {
        state.agrodata_1_d[`${action.payload.galponSelectedGwToken}`] =
          action.payload.response.data[0];
      }
    },
    updateAgrodata_ac: (state, action) => {
      if (
        action.payload.galponSelectedGwToken ===
        action.payload.response.data[0].gwtoken
      ) {
        state.agrodata_ac[`${action.payload.galponSelectedGwToken}`] =
          action.payload.response.data[0];
      }
    },
    updateDsviews: (state, action) => {
      if (
        action.payload.galponSelectedGwToken ===
        action.payload.response.data[0].gwtoken
      ) {
        state.dsViews[`${action.payload.galponSelectedGwToken}`] =
          action.payload.response.data[0];
      }
    },
    updateInicioCampania: (state, action) => {
      if (
        action.payload.galponSelectedGwToken ===
        action.payload.response.data[0].gwtoken
      ) {
        state.inicioCampania[`${action.payload.galponSelectedGwToken}`] =
          action.payload.response.data[0];
      }
    },
    setChangedFormValues: (state, action) => {
      state.changedFormValues = Object.assign(
        state.changedFormValues,
        action.payload
      );
    },
    clearChangedFormValues: state => {
      state.changedFormValues = {};
    }
  },

  extraReducers: builder => {
    builder.addCase(getVariables.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.variable = action.payload;
    });
    builder.addCase(getVariables.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getDsViews.pending, (state, action) => {
      state.statusDsViews = "loading";
    });
    builder.addCase(getDsViews.fulfilled, (state, action) => {
      state.statusDsViews = "succeeded";
      if (action.payload) state.dsViews[`${action.meta.arg}`] = action.payload;
    });
    builder.addCase(getDsViews.rejected, (state, action) => {
      state.statusDsViews = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getInicioCampania.pending, (state, action) => {
      state.statusInicioDeCampania = "loading";
    });
    builder.addCase(getInicioCampania.fulfilled, (state, action) => {
      state.statusInicioDeCampania = "succeeded";
      if (action.payload)
        state.inicioCampania[`${action.payload.gwtoken}`] = action.payload;
    });
    builder.addCase(getInicioCampania.rejected, (state, action) => {
      state.statusInicioDeCampania = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getAgrodata_30_s.fulfilled, (state, action) => {
      state.statusAgrodata_30_s = "succeeded";
      if (action.payload)
        state.agrodata_30_s[`${action.payload.gwtoken}`] = action.payload;
    });
    builder.addCase(getAgrodata_30_s.rejected, (state, action) => {
      state.statusAgrodata_30_s = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getAgrodata_30_s.pending, (state, action) => {
      state.statusAgrodata_30_s = "loading";
    });
    builder.addCase(getAgrodata_1_m.pending, (state, action) => {
      state.statusAgrodata_1_m = "loading";
    });
    builder.addCase(getAgrodata_1_m.fulfilled, (state, action) => {
      state.statusAgrodata_1_m = "succeeded";
      if (action.payload)
        state.agrodata_1_m[`${action.payload.gwtoken}`] = action.payload;
    });
    builder.addCase(getAgrodata_1_m.rejected, (state, action) => {
      state.statusAgrodata_1_m = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getAgrodata_1_h.fulfilled, (state, action) => {
      state.statusAgrodata_1_h = "succeeded";
      if (action.payload)
        state.agrodata_1_h[`${action.payload.gwtoken}`] = action.payload;
    });
    builder.addCase(getAgrodata_1_h.pending, (state, action) => {
      state.statusAgrodata_1_h = "loading";
    });
    builder.addCase(getAgrodata_1_h.rejected, (state, action) => {
      state.statusAgrodata_1_h = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getAgrodata_1_d.fulfilled, (state, action) => {
      state.statusAgrodata_1_d = "succeeded";
      if (action.payload)
        state.agrodata_1_d[`${action.payload.gwtoken}`] = action.payload;
    });
    builder.addCase(getAgrodata_1_d.rejected, (state, action) => {
      state.statusAgrodata_1_d = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getAgrodata_1_d.pending, (state, action) => {
      state.statusAgrodata_1_d = "loading";
    });
    builder.addCase(getAgrodata_ac.fulfilled, (state, action) => {
      state.statusAgrodata_ac = "succeeded";
      if (action.payload)
        state.agrodata_ac[`${action.payload.gwtoken}`] = action.payload;
    });
    builder.addCase(getAgrodata_ac.rejected, (state, action) => {
      state.statusAgrodata_ac = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getAgrodata_ac.pending, (state, action) => {
      state.statusAgrodata_ac = "loading";
    });
  }
});

// Action creators are generated for each case reducer function
// export const { selectPlantel } = variableSlice.actions;

export const {
  updateAgrodata_30_s,
  updateAgrodata_1_h,
  updateAgrodata_1_m,
  updateAgrodata_1_d,
  updateAgrodata_ac,
  updateDsviews,
  updateInicioCampania,
  setSocketSpinnerLoading,
  setChangedFormValues,
  clearChangedFormValues,
  clearAgrodata
} = variableSlice.actions;
export default variableSlice.reducer;
