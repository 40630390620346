import { createAsyncThunk } from "@reduxjs/toolkit";
export const getAlarmDataByGalponId = createAsyncThunk(
  "get-alarms",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.alarms.getAlarmDataByGalponId(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const updateAlarmData = createAsyncThunk(
  "update-alarms",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.alarms.updateAlarmData(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
