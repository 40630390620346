import { createAsyncThunk } from "@reduxjs/toolkit";
export const getFarms = createAsyncThunk(
  "get-farms",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.farm.getFarmsByCompany();
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getFarm = createAsyncThunk(
  "get-farm",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.farm.getFarm(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const createGranja = createAsyncThunk(
  "create-farms",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.farm.createFarm(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const deleteFarm = createAsyncThunk(
  "delete-farm",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.farm.deleteFarm(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const updateSelectedFarm = createAsyncThunk(
  "update-farm",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.farm.updateFarm(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
