import { axiosInstance } from "./api";

export default {
  getUserRol: id =>
    axiosInstance.get(
      `rol?$getRolByUser=true&userId=${id}&$select[]=nombre&$select[]=id&estado=1`
    ),
  getRoles: () =>
    axiosInstance.get(`/rol/?$select[]=nombre&$select[]=id&estado=1`)
};
