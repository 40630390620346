/* eslint-disable no-unused-vars */
import {
  updateSelectedFarmIdState,
  updateSelectedFarmNameState,
  updateSelectedFarmHouseIdState,
  updateSelectedFarmHouseNameState
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  selectedFarmId: 0,
  selectedFarmName: 0,
  selectedFarmHouseId: 0,
  selectedFarmHouseName: 0
};

const settings = handleActions(
  new Map([
    [
      updateSelectedFarmIdState,
      (state, action) => ({
        ...state,
        selectedFarmId: action.payload.selectedFarmId
      })
    ],
    [
      updateSelectedFarmNameState,
      (state, action) => ({
        ...state,
        selectedFarmName: action.payload.selectedFarmName
      })
    ],
    [
      updateSelectedFarmHouseIdState,
      (state, action) => ({
        ...state,
        selectedFarmHouseId: action.payload.selectedFarmHouseId
      })
    ],
    [
      updateSelectedFarmHouseNameState,
      (state, action) => ({
        ...state,
        selectedFarmHouseName: action.payload.selectedFarmHouseName
      })
    ]
  ]),
  initialState
);

export default settings;
