import { createAsyncThunk } from "@reduxjs/toolkit";
export const getSensor = createAsyncThunk(
  "get-sensor",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.sensors.getSensorById(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const getAllSensors = createAsyncThunk(
  "get-all-sensors",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.sensors.getSensors();
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const postSensorAlarm = createAsyncThunk(
  "create-sensors",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.sensors.createSensorAlarm(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const deleteSensor = createAsyncThunk(
  "create-sensors",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.sensors.deleteSensor(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const updateSelectedSensor = createAsyncThunk(
  "update-sensors",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.sensors.updateSensor(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
