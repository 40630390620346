import { createAsyncThunk } from "@reduxjs/toolkit";
export const getGalpones = createAsyncThunk(
  "get-galpones",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.galpon.getGalponesByPlantel(argument);
      const galpones = res.data.data.map(item => {
        return {
          ...item,
          id_mtech_number: parseInt(item.id_mtech)
        };
      });

      return (
        galpones.sort((a, b) => {
          return a.id_mtech_number - b.id_mtech_number;
        }) ?? []
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const getGalponesWithTrendRP = createAsyncThunk(
  "get-galpones-trend-rp",
  async (argument, thunkAPI) => {
    try {
      const galponesTrend = await thunkAPI.extra.galpon.getGalponesTrend_RP_ByPlantel(
        argument
      );

      return galponesTrend.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getGalponesWithTrendCP = createAsyncThunk(
  "get-galpones-trend-cp",
  async (argument, thunkAPI) => {
    try {
      const galponesTrend = await thunkAPI.extra.galpon.getGalponesTrend_CP_ByPlantel(
        argument
      );

      return galponesTrend.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);

export const getAllGalpones = createAsyncThunk(
  "get-all-galpones",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.galpon.getAllGalpones();
      const galpones = res.data.data.map(item => {
        return {
          ...item,
          id_mtech_number: parseInt(item.id_mtech)
        };
      });

      return (
        galpones.sort((a, b) => {
          return a.id_mtech_number - b.id_mtech_number;
        }) ?? []
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const getGalpon = createAsyncThunk(
  "get-galpon",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.galpon.getGalponById(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const createGalpon = createAsyncThunk(
  "create-galpon",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.galpon.createChickenCoop(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const deleteGalpon = createAsyncThunk(
  "delete-galpon",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.galpon.deleteChickenCoop(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
export const updateSelectedGalpon = createAsyncThunk(
  "update-galpon",
  async (argument, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.galpon.updateChickenCoop(argument);
      return res.data.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data.errorMessages);
    }
  }
);
