/*eslint-disable*/
import { Component } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Switch from '@mui/material/Switch';
// nodejs library that concatenates classes
import styles from "assets/jss/components/configMenuStyle.js";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from '@mui/styles/withStyles';
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import classNames from "classnames";


const useStyles = makeStyles(styles);

export default function ConfigMenu(props) {
  const classes = useStyles();

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
      overflow: 'inherit'
    },
    switchBase: {
      padding: 1,
      color: '#3b3b3b',
      '&$checked': {
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#3b3b3b',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#3b3b3b',
        border: '6px solid #3b3b3b',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });
  

  const winds = (
    <>
      <div className={classes.subtitleHeader}>
        <label className={classes.subtitle}>Unidad de viento</label>
      </div>
      <Grid container>
        <Grid item xs={2}></Grid>
          <Grid item xs={3} className={
              clsx(classes.optionsLabels, props.unit === "knots" ? classes.selected : classes.unselected)
              }
              onClick={() =>{
                  props.handleUnitClick("knots")
              }}>
                  Knots
          </Grid>
          <Grid item xs={3} className={
              clsx(classes.optionsLabels, props.unit === "km/h" ? classes.selected : classes.unselected)
              }
              onClick={() =>{
                  props.handleUnitClick("km/h")
              }}>
                  Km/h
          </Grid>
          <Grid item xs={3} className={
              clsx(classes.optionsLabels, props.unit === "m/s" ? classes.selected : classes.unselected)
              }
              onClick={() =>{
                  props.handleUnitClick("m/s")
              }}>
                  m/s
          </Grid>
      </Grid>
      </>
  );

  const theme = (
    <>
      <div className={classes.subtitleHeader}>
        <label className={classes.subtitle}>Modo oscuro</label>
      </div>
      
      <Grid 
        container 
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{opacity:0.4}}>
        <Grid item xs={6}>
            <label className={classes.subtitle}>Off</label>
              <IOSSwitch disabled={true} checked={props.theme === "dark" ? true : false} onChange={props.handleSwitchTheme} name="checkedC" />
            <label className={classes.subtitle}>On</label>
        </Grid>
      </Grid>
      </>
  );

  // const tidesAndCurrents = (
  //   <>
  //     <div className={classes.subtitleHeader}>
  //       <label className={classes.subtitle}>Unidades de corrientes</label>
  //     </div>
  //     <Grid container>
  //       <Grid item xs={2}></Grid>
  //         <Grid item xs={3} className={
  //             clsx(classes.optionsLabels, props.currentsUnit === "cm/s" ? classes.selected : classes.unselected)
  //             }
  //             onClick={() =>{
  //                 props.handleCurrentsUnitClick("cm/s")
  //             }}>
  //                 cm/s
  //         </Grid>
  //         <Grid item xs={3} className={
  //             clsx(classes.optionsLabels, props.currentsUnit === "m/s" ? classes.selected : classes.unselected)
  //             }
  //             onClick={() =>{
  //                 props.handleCurrentsUnitClick("m/s")
  //             }}>
  //                 m/s
  //         </Grid>
  //     </Grid>
  //     </>
  // );

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={props.open}
        classes={{
          paper: classNames(classes.drawerPaper, {
            [classes.drawerPaperRTL]: props.rtlActive
          })
        }}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
      <div className={classes.titleHeader}>
        <label className={classes.title}>Parametros generales</label>
      </div>
        <div className={classes.sidebarWrapper}>{theme}</div>
        <div className={classes.sidebarWrapper}>{winds}</div>
        {/* <div className={classes.sidebarWrapper}>{tidesAndCurrents}</div> */}
        <div
          className={classes.background}
          style={{ backgroundColor: "grey" }}
        />
      </Drawer>
    </div>
  );
}

ConfigMenu.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  rtlActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func
};
