/* eslint-disable no-unused-vars */
import {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail,
  updateScreen
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  data: {
    in_temp_s1: 0,
    in_temp_s2: 0,
    in_temp_s3: 0,
    in_temp_s4: 0,
    grow_day: 0,
    in_avg_temp: 0,
    out_temp: 0,
    in_hum: 0,
    curr_CO2: 0,
    day_water_cons: 0,
    day_feed_cons: 0,
    silo1: 0,
    silo2: 0,
    curr_cool_curtain: 0,
    day_mor: 0,
    total_mor: 0,
    vent_stage: 0
  },
  currentScreen: "render",
  loading: false,
  error: ""
};

const vars = handleActions(
  new Map([
    [
      updateScreen,
      (state, action) => ({
        ...state,
        currentScreen: action.payload.data
      })
    ],
    [
      fetchDataStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataSuccess,
      (state, action) => ({
        // Mapear correctamente los datos aca
        ...state,
        data: action.payload.data,
        loading: false
      })
    ],
    [
      fetchDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    [
      initializeStoreDataStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      initializeStoreDataSuccess,
      (state, action) => ({
        ...state,
        loading: false
      })
    ],
    [
      initializeStoreDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ]
  ]),
  initialState
);

export default vars;
