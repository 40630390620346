import { lazy } from "react";
import { Roles } from "utils/helpers";
import Alarm from "../../../assets/img/navBar/alarma.svg";
import Balanza from "../../../assets/img/navBar/balanza.svg";
import Configuracion from "../../../assets/img/navBar/configuracion.svg";
import Criadoras from "../../../assets/img/navBar/criadoras.svg";
import Data from "../../../assets/img/navBar/data.svg";
import Entradas from "../../../assets/img/navBar/entradas.svg";
import Luz from "../../../assets/img/navBar/luz.svg";
import Manual from "../../../assets/img/navBar/manual.svg";
import Paneles from "../../../assets/img/navBar/paneles.svg";
import Presion from "../../../assets/img/navBar/presion.svg";
import Silo from "../../../assets/img/navBar/silo.svg";
import Ventilacion from "../../../assets/img/navBar/ventilacion.svg";

const MainScreen = lazy(() => import("./components/screens/main/mainScreen"));
const SiloScreen = lazy(() => import("./components/screens/silo/siloScreen"));
const LuzScreen = lazy(() => import("./components/screens/luz/luzScreen"));
const LuzDimerScreen = lazy(() => import("./components/screens/luz/luzDimer"));
const LuzPeriodosScreen = lazy(() =>
  import("./components/screens/luz/luzPeriodos")
);
const DataScreen = lazy(() => import("./components/screens/data/dataScreen"));
const BalanzaScreen = lazy(() =>
  import("./components/screens/balanza/balanzaScreen.jsx")
);
const CriadorasScreen = lazy(() =>
  import("./components/screens/criadoras/criadorasScreen")
);
const PresionScreen = lazy(() =>
  import("./components/screens/presion/presionScreen")
);
const VentilacionScreen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionScreen")
);
const PanelesScreen = lazy(() =>
  import("./components/screens/paneles/panelesScreen")
);
const Configuracion1Screen = lazy(() =>
  import("./components/screens/configuracion/configuracion1Screen")
);
const EntradasScreen = lazy(() =>
  import("./components/screens/entradas/entradasScreen")
);
const AlarmasScreen = lazy(() =>
  import("./components/screens/alarmas/alarmasScreen")
);
const ManualScreen = lazy(() =>
  import("./components/screens/manual/manualScreen")
);
const CalibracionBalanzaScreen = lazy(() =>
  import("./components/screens/balanza/calibracionScreen")
);
const TablaPesosScreen = lazy(() =>
  import("./components/screens/balanza/tablaScreen")
);
const EntradasMenu1Screen = lazy(() =>
  import("./components/screens/entradas/entradasMenu1")
);
const EntradasMenu2Screen = lazy(() =>
  import("./components/screens/entradas/entradasMenu2")
);
const VentilacionEtapasScreen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionEtapas")
);
const VentilacionEtapaVsDiaScreen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionEtapaVsDia")
);
const VentilacionEntradasScreen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionEntradas")
);
const VentilacionControlHumedadScreen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionControlHumedad")
);
const VentilacionControlCO2Screen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionControlCo2")
);
const VentilacionControlCO22Screen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionControlCo22")
);
const VentilacionMinima1Screen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionMinima1")
);
const VentilacionMinima2Screen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionMinima2")
);
const VentilacionConfig1Screen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionConfig1")
);
const VentilacionConfig2Screen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionConfig2")
);
const VentilacionAsignacionSensoresScreen = lazy(() =>
  import("./components/screens/ventilacion/ventilacionAsignacionSensores")
);
const Configuracion2Screen = lazy(() =>
  import("./components/screens/configuracion/configuracion2Screen")
);
const Configuracion3Screen = lazy(() =>
  import("./components/screens/configuracion/configuracion3Screen")
);
const Configuracion4Screen = lazy(() =>
  import("./components/screens/configuracion/configuracion4Screen")
);
const Configuracion5Screen = lazy(() =>
  import("./components/screens/configuracion/configuracion5Screen")
);
const ConfiguracionTemperaturasScreen = lazy(() =>
  import("./components/screens/configuracion/configuracionTemperaturas")
);
const WaterAndFoodScreen = lazy(() =>
  import("./components/screens/data/waterAndFoodScreen")
);
const FoodConsumeScreen = lazy(() =>
  import("./components/screens/data/foodConsumeScreen")
);
const WaterConsumeScreen = lazy(() =>
  import("./components/screens/data/waterConsumeScreen")
);
const TimeOnHeatingScreen = lazy(() =>
  import("./components/screens/data/timeOnHeatingScreen")
);
const AlarmasType1 = lazy(() =>
  import("./components/screens/alarmas/alarmasType1")
);
const AlarmasType2 = lazy(() =>
  import("./components/screens/alarmas/alarmasType2")
);
const AlarmasCO2 = lazy(() =>
  import("./components/screens/alarmas/alarmasCO2")
);
const AlarmasAgua = lazy(() =>
  import("./components/screens/alarmas/alarmasAgua")
);
const TablaConsumoAgua = lazy(() =>
  import("./components/screens/alarmas/tablaConsumoAgua")
);
const AlarmasPresionEstatica = lazy(() =>
  import("./components/screens/alarmas/alarmasPresionEstatica")
);
const AlarmasEntradasAire = lazy(() =>
  import("./components/screens/alarmas/alarmasEntradasAire")
);
const AlarmasFrio = lazy(() =>
  import("./components/screens/alarmas/alarmasFrio")
);
const AlarmasCalor = lazy(() =>
  import("./components/screens/alarmas/alarmasCalor")
);
const ManualMenu1Screen = lazy(() =>
  import("./components/screens/manual/manualMenu1")
);
const ManualMenu2Screen = lazy(() =>
  import("./components/screens/manual/manualMenu2")
);
const DailyDataScreen = lazy(() =>
  import("./components/screens/data/dailyDataScreen")
);

export const VIEWS = {
  DEFAULT: "default",
  VARS: "vars",
  GROUP: "group",
  PARALEL: "paralel"
};

export const VIEWS_DROPDOWN = [
  {
    id: VIEWS.DEFAULT,
    name: "Vista predeterminada"
  },
  {
    id: VIEWS.VARS,
    name: "Vista de Variables"
  },
  {
    id: VIEWS.GROUP,
    name: "Vista grupal"
  },
  {
    id: VIEWS.PARALEL,
    name: "Vista paralela"
  }
];

export const SCREENS = [
  {
    name: "balanza",
    title: "Balanza",
    component: Balanza,
    placement: "bottom",
    screen: BalanzaScreen
  },
  {
    name: "silo",
    title: "Silo",
    component: Silo,
    placement: "bottom",
    screen: SiloScreen
  },
  {
    name: "criadoras",
    title: "Criadoras",
    component: Criadoras,
    placement: "bottom",
    screen: CriadorasScreen
  },
  {
    name: "luz",
    title: "Luz",
    component: Luz,
    placement: "bottom",
    screen: LuzScreen
  },
  {
    name: "entradas",
    title: "Entradas",
    component: Entradas,
    placement: "bottom",
    screen: EntradasScreen
  },
  {
    name: "presion",
    title: "Presión",
    component: Presion,
    placement: "bottom",
    screen: PresionScreen
  },
  {
    name: "ventilacion",
    title: "Ventilación",
    component: Ventilacion,
    placement: "bottom",
    screen: VentilacionScreen
  },
  {
    name: "paneles",
    title: "Paneles",
    component: Paneles,
    placement: "bottom",
    screen: PanelesScreen
  },
  {
    name: "configuracion",
    title: "Configuracion",
    component: Configuracion,
    placement: "bottom",
    screen: Configuracion1Screen
  },
  {
    name: "data",
    title: "Data",
    component: Data,
    placement: "bottom",
    screen: DataScreen
  },
  {
    name: "alarma",
    title: "Alarma",
    component: Alarm,
    placement: "bottom",
    screen: AlarmasScreen
  },
  {
    name: "manual",
    title: "Manual",
    component: Manual,
    placement: "bottom",
    screen: ManualScreen
  }
];

export const hasLimitedView = () => {
  const selectedRol = sessionStorage.getItem("usuarioRol");
  return [Roles.Supervisor, Roles.Usuario].includes(selectedRol);
};

export const getDropdown = () => {
  const hasLimitedDropdown = hasLimitedView();
  return {
    VIEWS_DROPDOWN: hasLimitedDropdown
      ? [
          {
            id: VIEWS.DEFAULT,
            name: "Vista predeterminada"
          },
          {
            id: VIEWS.VARS,
            name: "Vista de Variables"
          }
        ]
      : VIEWS_DROPDOWN
  };
};

export const TOTALSCREENS = [
  {
    id: 1,
    title: "Vista Principal"
  },
  {
    id: 2,
    title: "Balanzas Pesaje"
  },
  {
    id: 3,
    title: "Calibración Balanzas Pesaje"
  },
  {
    id: 4,
    title: "Báscula - Tabla Pesos Esperados"
  },
  {
    id: 5,
    title: "Silo"
  },
  {
    id: 6,
    title: "Criadoras"
  },
  {
    id: 7,
    title: "Luz"
  },
  {
    id: 8,
    title: "Luz Dimmer"
  },
  {
    id: 9,
    title: "Luz - Períodos  Adicionales"
  },
  {
    id: 10,
    title: "Entradas Menú 1"
  },
  {
    id: 11,
    title: "Entradas Menú 2"
  },
  {
    id: 12,
    title: "Presión"
  },
  {
    id: 13,
    title: "Ventilación Etapas Temperatura(1-20)"
  },
  {
    id: 14,
    title: "Ventilación Etapas Temperatura vs Día"
  },
  {
    id: 15,
    title: "Ventilación Configuración Entradas Aire Proporcional"
  },
  {
    id: 16,
    title: "Ventilación Control Humedad"
  },
  {
    id: 17,
    title: "Ventilación Configuración CO2 (1)"
  },
  {
    id: 18,
    title: "Ventilación Configuración CO2 (2)"
  },
  {
    id: 19,
    title: "Ventilación Configuración 1"
  },
  {
    id: 20,
    title: "Ventilación Configuración 2"
  },
  {
    id: 21,
    title: "Ventilación Asignación Sensores"
  },
  {
    id: 22,
    title: "Ventilación Mínima 1"
  },
  {
    id: 23,
    title: "Ventilación Mínima 2"
  },
  {
    id: 24,
    title: "Paneles"
  },
  {
    id: 25,
    title: "Configuración 1"
  },
  {
    id: 26,
    title: "Configuración 2"
  },
  {
    id: 27,
    title: "Configuración 3"
  },
  {
    id: 28,
    title: "Configuración 4"
  },
  {
    id: 29,
    title: "Configuración 5"
  },
  {
    id: 30,
    title: "Configuración Tabla Temperaturas Requeridas"
  },
  {
    id: 31,
    title: "Data Diaria"
  },
  {
    id: 32,
    title: "Consumo de Alimento y Agua"
  },
  {
    id: 33,
    title: "Consumo de Alimento Estándar"
  },
  {
    id: 34,
    title: "Consumo de Agua Estándar"
  },
  {
    id: 35,
    title: "Tiempo Encendido Zonas Calefacción"
  },
  {
    id: 36,
    title: "Alarmas Tipo 1"
  },
  {
    id: 37,
    title: "Alarmas Tipo 2"
  },
  {
    id: 38,
    title: "Alarmas CO2"
  },
  {
    id: 39,
    title: "Alarmas Agua"
  },
  {
    id: 40,
    title: "Alarmas Tabla Consumo Agua"
  },
  {
    id: 41,
    title: "Alarmas Presión Estática"
  },
  {
    id: 42,
    title: "Alarmas Entradas Aire"
  },
  {
    id: 43,
    title: "Alarmas Frio"
  },
  {
    id: 44,
    title: "Alarmas Calor"
  },
  {
    id: 45,
    title: "Relays Activación Manual (1)"
  },
  {
    id: 46,
    title: "Relays Activación Manual (2)"
  }
];

export const parallelViewDefaultScreens = [
  {
    id: 1,
    title: "Vista Principal",
    isCheck: true,
    order: 1
  },
  {
    id: 2,
    title: "Balanzas Pesaje",
    isCheck: true,
    order: 2
  },
  {
    id: 6,
    title: "Criadoras",
    isCheck: true,
    order: 3
  },
  {
    id: 12,
    title: "Presión",
    isCheck: true,
    order: 4
  },
  {
    id: 21,
    title: "Ventilación Asignacion Sensores",
    isCheck: true,
    order: 5
  },
  {
    id: 31,
    title: "Data Diaria",
    isCheck: true,
    order: 6
  }
];

export const ScreensToRender = {
  [1]: MainScreen,
  [2]: BalanzaScreen,
  [3]: CalibracionBalanzaScreen,
  [4]: TablaPesosScreen,
  [5]: SiloScreen,
  [6]: CriadorasScreen,
  [7]: LuzScreen,
  [8]: LuzDimerScreen,
  [9]: LuzPeriodosScreen,
  [10]: EntradasMenu1Screen,
  [11]: EntradasMenu2Screen,
  [12]: PresionScreen,
  [13]: VentilacionEtapasScreen,
  [14]: VentilacionEtapaVsDiaScreen,
  [15]: VentilacionEntradasScreen,
  [16]: VentilacionControlHumedadScreen,
  [17]: VentilacionControlCO2Screen,
  [18]: VentilacionControlCO22Screen,
  [19]: VentilacionConfig1Screen,
  [20]: VentilacionConfig2Screen,
  [21]: VentilacionAsignacionSensoresScreen,
  [22]: VentilacionMinima1Screen,
  [23]: VentilacionMinima2Screen,
  [24]: PanelesScreen,
  [25]: Configuracion1Screen,
  [26]: Configuracion2Screen,
  [27]: Configuracion3Screen,
  [28]: Configuracion4Screen,
  [29]: Configuracion5Screen,
  [30]: ConfiguracionTemperaturasScreen,
  [31]: DataScreen,
  [32]: WaterAndFoodScreen,
  [33]: FoodConsumeScreen,
  [34]: WaterConsumeScreen,
  [35]: TimeOnHeatingScreen,
  [36]: AlarmasType1,
  [37]: AlarmasType2,
  [38]: AlarmasCO2,
  [39]: AlarmasAgua,
  [40]: TablaConsumoAgua,
  [41]: AlarmasPresionEstatica,
  [42]: AlarmasEntradasAire,
  [43]: AlarmasFrio,
  [44]: AlarmasCalor,
  [45]: ManualMenu1Screen,
  [46]: ManualMenu2Screen
};
