import axios from "axios";
import vars from "./vars";
import iotMap from "./iotMap";
import auth from "./auth";
import alarms from "./alarms";
import farm from "./farm";
import plantel from "./plantel";
import galpon from "./galpon";
import variable from "./variable";
import sensors from "./sensors";
import timeline from "./timeline";
import users from "./users";
import rol from "./rol";
import typeVariable from "./typeVariable";
import basePermissions from "./basePermissions";
import { getAuthToken, setAuthToken } from "../utils/auth.js";

export const API_URL =
  process.env.REACT_APP_JSON_SERVER === "true"
    ? `${process.env.REACT_APP_API_HOST_JSON_SERVER}${process.env.REACT_APP_API_PATH}`
    : `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}`;
//export const WEB_URL = `${process.env.REACT_APP_WEB_HOST}${process.env.REACT_APP_API_PATH}`;

// ----------------- //
// Instancia anónima //
// ----------------- //

export const axiosAnonInstance = axios.create({
  baseURL: API_URL
});

axiosAnonInstance.interceptors.response.use(response => {
  return response;
});

axiosAnonInstance.interceptors.request.use(async config => {
  return config;
});

// ----------------- //
// Instancia con token //
// ----------------- //

const axiosInstanceAux = axios.create({
  baseURL: API_URL
});

axiosInstanceAux.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      // console.log(`err: ${JSON.stringify(err)}`);
      if (
        err.response &&
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest &&
        "/login" !== window.location.pathname
      ) {
        originalReq._retry = true;
        sessionStorage.clear();
        window.location.replace("/login");
        let res = auth.login().then(res => {
          setAuthToken(res.data.accessToken);
          originalReq.headers["Authorization"] =
            "bearer " + res.data.accessToken;
          return axios(originalReq);
        });
        resolve(res);
      }
      reject(err);
    });
  }
);

axiosInstanceAux.interceptors.request.use(async config => {
  const token = getAuthToken();
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

export const axiosInstance =
  process.env.REACT_APP_JSON_SERVER === "true"
    ? axiosAnonInstance
    : axiosInstanceAux;

export default {
  vars,
  iotMap,
  auth,
  alarms,
  farm,
  plantel,
  galpon,
  variable,
  sensors,
  timeline,
  users,
  rol,
  typeVariable,
  basePermissions
};
