import { axiosInstance } from "./api";
export default {
  getFarmsByCompany: () =>
    axiosInstance.get(
      "/granja?$sort[id]=-1&$limit=100&empresaId=" +
        sessionStorage.getItem("empresa")
    ),
  getFarmsHousesByFarm: farm =>
    axiosInstance.get(
      "galpon?$sort[id]=-1&$limit=40&empresaId=" +
        sessionStorage.getItem("empresa") +
        "&granjaId=" +
        farm
    )
};
