import { axiosInstance } from "./api";

export default {
  getFarmsByCompany: () =>
    axiosInstance.get(
      "/granja?$sort[id]=-1&$limit=100&empresaId=" +
        sessionStorage.getItem("empresa")
    ),
  getFarmsHousesByFarm: farm =>
    axiosInstance.get(
      "galpon?$sort[id]=-1&$limit=40&empresaId=" +
        sessionStorage.getItem("empresa") +
        "&granjaId=" +
        farm
    )
  // getData: (galponId, farmId) =>
  //   //console.log("agrodata?$sort[horamuestra]=-1&$limit=1&empresa=" + sessionStorage.getItem('empresa') + "&centro=" + farmHouse+ "&granjaId=" + farm),
  //   axiosInstance.get(
  //     "datastore?$sort[horamuestra]=-1&$limit=1&empresa=" +
  //       sessionStorage.getItem("empresa") +
  //       "&galpon=" +
  //       galponId +
  //       "&granja=" +
  //       farmId
  //   ),
  // getData: gwtoken =>
  //   //console.log("agrodata?$sort[horamuestra]=-1&$limit=1&empresa=" + sessionStorage.getItem('empresa') + "&centro=" + farmHouse+ "&granjaId=" + farm),
  //   axiosInstance.get("datastore?$lastupdate=true&gwtoken=" + gwtoken)
};
