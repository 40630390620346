import { createSlice, current } from "@reduxjs/toolkit";
import {
  getGalpones,
  getGalponesWithTrendRP,
  getGalponesWithTrendCP,
  getAllGalpones,
  createGalpon,
  deleteGalpon,
  getGalpon,
  updateSelectedGalpon
} from "./thunks";

export const galponSlice = createSlice({
  name: "galpon",
  initialState: {
    galpones: [],
    galponesTrend_RP: [],
    galponesTrend_CP: [],
    selectedGalpon: {},
    selectedGalponStatus: "idle",
    galponSelectedId: null,
    galponSelectedGwToken: null,
    status: "idle",
    deleteStatus: "idle",
    updateGalponStatus: "idle",
    error: null
  },
  reducers: {
    selectGalpon: (state, action) => {
      state.galponSelectedId = action.payload;
    },
    selectGwToken: (state, action) => {
      state.galponSelectedGwToken = action.payload;
    },
    updateGalponesTrend_CP: (state, action) => {
      const newGalpones = action.payload;
      const oldGalpones = current(state.galponesTrend_CP);
      // console.log("oldGalpones", oldGalpones);
      const resultGalpones = oldGalpones.map(oldGalpon => {
        for (let index = 0; index < newGalpones.length; index++) {
          const newGalpon = newGalpones[index];
          if (newGalpon.gwtoken === oldGalpon.gwtoken) {
            return newGalpon;
          }
        }
        return oldGalpon;
      });
      // console.log("resultGalpones", resultGalpones);
      state.galponesTrend_CP = resultGalpones;
    },
    updateGalponesTrend_RP: (state, action) => {
      const newGalpones = action.payload;
      const oldGalpones = current(state.galponesTrend_RP);
      // console.log("oldGalpones", oldGalpones);
      const resultGalpones = oldGalpones.map(oldGalpon => {
        for (let index = 0; index < newGalpones.length; index++) {
          const newGalpon = newGalpones[index];
          if (newGalpon.gwtoken === oldGalpon.gwtoken) {
            return newGalpon;
          }
        }
        return oldGalpon;
      });
      // console.log("resultGalpones", resultGalpones);
      state.galponesTrend_RP = resultGalpones;
    }
  },

  extraReducers: builder => {
    builder.addCase(getGalpones.pending, (state, action) => {
      state.status = "loading";
      state.galpones = [];
      state.galponSelectedId = null;
      state.galponSelectedGwToken = null;
    });
    builder.addCase(getGalpones.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.galpones = action.payload;
      state.updateGalponStatus = "idle";
    });
    builder.addCase(getGalpones.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.galpones = [];
      state.galponSelectedId = null;
      state.galponSelectedGwToken = null;
    });

    builder.addCase(getGalponesWithTrendRP.pending, (state, action) => {
      state.status = "loading";
      state.galponesTrend_RP = [];
    });
    builder.addCase(getGalponesWithTrendRP.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.galponesTrend_RP = action.payload;
    });
    builder.addCase(getGalponesWithTrendRP.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.galponesTrend_RP = [];
    });

    builder.addCase(getGalponesWithTrendCP.pending, (state, action) => {
      state.status = "loading";
      state.galponesTrend_CP = [];
    });
    builder.addCase(getGalponesWithTrendCP.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.galponesTrend_CP = action.payload;
    });
    builder.addCase(getGalponesWithTrendCP.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.galponesTrend_CP = [];
    });

    builder.addCase(getAllGalpones.pending, (state, action) => {
      state.status = "loading";
      state.galpones = [];
    });
    builder.addCase(getAllGalpones.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.galpones = action.payload;
    });
    builder.addCase(getAllGalpones.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.galpones = [];
    });

    builder.addCase(createGalpon.pending, (state, action) => {
      state.status = "loading";
      state.galpones = [];
    });
    builder.addCase(createGalpon.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.galpones = [...state.galpones, action.payload];
    });
    builder.addCase(createGalpon.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.galpones = [];
    });
    builder.addCase(deleteGalpon.pending, (state, action) => {
      state.deleteStatus = "loading";
    });
    builder.addCase(deleteGalpon.fulfilled, (state, action) => {
      state.deleteStatus = "succeeded";
    });
    builder.addCase(deleteGalpon.rejected, (state, action) => {
      state.deleteStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getGalpon.pending, (state, action) => {
      state.selectedGalponStatus = "loading";
    });
    builder.addCase(getGalpon.fulfilled, (state, action) => {
      state.selectedGalponStatus = "succeeded";
      state.selectedGalpon = action.payload;
    });
    builder.addCase(getGalpon.rejected, (state, action) => {
      state.selectedGalponStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(updateSelectedGalpon.pending, (state, action) => {
      state.updateGalponStatus = "loading";
    });
    builder.addCase(updateSelectedGalpon.fulfilled, (state, action) => {
      state.updateGalponStatus = "succeeded";
    });
    builder.addCase(updateSelectedGalpon.rejected, (state, action) => {
      state.updateGalponStatus = "failed";
      state.error = action.error.message;
    });
  }
});

// Action creators are generated for each case reducer function
export const {
  selectGalpon,
  selectGwToken,
  updateGalponesTrend_CP,
  updateGalponesTrend_RP
} = galponSlice.actions;
export default galponSlice.reducer;
