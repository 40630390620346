/* eslint-disable react/prop-types */
import { useEffect, Fragment } from "react";
import { AppRoutes } from "./routes";
import { connect } from "react-redux";
import ModalRootContainer from "containers/common/modalRoot/modalRoot";
import { Router } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { createBrowserHistory } from "history";
import Layout from "components/layouts/layout/layout";
import SettingsManagerContainer from "containers/common/settingsManager/settingsManager";
import DataControllerContainer from "containers/common/dataController/dataController";
import { getAuthToken } from "utils/auth.js";
import { socket } from "client/feathersClient";
import AppTheme from "utils/theme/appTheme";

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
  const { settings } = state;
  return { theme: settings.theme };
}

const hist = createBrowserHistory();

// eslint-disable-next-line no-unused-vars
const unlisten = hist.listen((location, action) => {});

// eslint-disable-next-line no-unused-vars
function App(props) {
  useEffect(() => {
    return () => {
      unlisten();
    };
  }, []);

  var token = getAuthToken();

  useEffect(() => {
    if (token !== null && token !== undefined) {
      // console.log("socket.on connect ");
      socket.on("connect", () => {
        socket.emit(
          "create",
          "authentication",
          {
            strategy: "jwt",
            accessToken: getAuthToken()
          },
          function(error, newAuthResult) {
            if (error !== null && error !== undefined) {
              sessionStorage.clear();
              window.location.replace("/login");
            }
          }
        );
      });
    }
  }, []);

  // console.log("DataControllerContainer = " + token);

  return (
    <div className="App">
      <Fragment>
        <Router history={hist}>
          {/* {token !== null && token !== undefined ? (
            <DataControllerContainer />
          ) : null} */}
          <SettingsManagerContainer />
          <StyledEngineProvider injectFirst>
            <AppTheme>
              <Layout>
                <AppRoutes />
              </Layout>
            </AppTheme>
          </StyledEngineProvider>
        </Router>
        <ModalRootContainer />
      </Fragment>
    </div>
  );
}

export default connect(mapStateToProps)(App);
